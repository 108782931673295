import React, { PureComponent } from 'react';
import { notification } from 'uikit';

import { LabeledControl, DividedTable, QuillField } from '../../Util';
import { firestoreDb, firebaseStorage, timestamp } from '../../../firebase';
import { withContext } from '../../../appState';

import { validateDataMapping, processDataMapping, dateFromTimestamp } from './ObjectDataMap';
import SearchObjectModal from './modals/SearchObjectModal';
import ConfigureObjectModal from './modals/ConfigureObjectModal';

import './DynamicObjectForm.scss';
import { humanFileSize } from '../../../configuration/projectConfiguration';
import { Link } from 'react-router-dom';

function currentTime() {
    return (new Date()).getTime();
}

class DynamicObjectForm extends PureComponent {
    search_modal_refs = [];
    configure_modal_refs = [];
    modals = [];
    constructor(props) {
        super(props);
        const processedData = props.workingData ? this.refreshWorkingData(props.workingData) : [];
        this.state = {
            workingData: processedData,
            refreshTs: currentTime()
        };
        this.setModals();
    }
    componentDidUpdate(prevProps, prevState) {
        const tsChanged = prevState.refreshTs !== this.state.refreshTs;
        const formKeyChanged = prevProps.formKey !== this.props.formKey;
        if (tsChanged) {
            //reprocess existing data
            this.setState((oldState) => ({
                workingData: this.refreshWorkingData(oldState.workingData),
            }));
        } else if (formKeyChanged) {
            //process new data
            this.setState(() => ({
                workingData: this.refreshWorkingData(this.props.workingData),
            }));
        }
    }
    refreshWorkingData = (workingData) => {
        const newData = { ...workingData };
        const { configuration } = this.props;
        const { objectFields } = configuration;
        for (const [okey, oval] of Object.entries(objectFields)) {
            const { type, fields = [], rowCalculationFn } = oval;
            if (type === 'array') {
                for (const subfield of fields) {
                    if (subfield.key) {
                        if (subfield.rowCalculationFn) {
                            for (const arrayIdx in newData[okey]) {
                                const calculatedValue = subfield.rowCalculationFn(newData[okey][arrayIdx]);
                                if (newData[okey][arrayIdx][subfield.key] !== calculatedValue)
                                    newData[okey][arrayIdx][subfield.key] = calculatedValue;
                            }
                        }
                    }
                }
            } else if (type === 'map') {
                for (const subfield of fields) {
                    if (subfield.key) {
                        if (subfield.rowCalculationFn) {
                            for (const mapKey of Object.keys(newData[okey])) {
                                const calculatedValue = subfield.rowCalculationFn(newData[okey][mapKey]);
                                if (newData[okey][mapKey][subfield.key] !== calculatedValue)
                                    newData[okey][mapKey][subfield.key] = calculatedValue;
                            }
                        }
                    }
                }
            } else if (rowCalculationFn) {
                const calculatedValue = rowCalculationFn(newData);
                if (calculatedValue !== newData[okey]) newData[okey] = calculatedValue;
            }
        }
        return newData;
    };
    setModals = () => {
        const { dataMapping, configuration, context } = this.props;
        const { objectFields } = configuration;
        //pre-calculate necessary modals so we can include all on edit screen
        let modals = [];
        for (const { key } of dataMapping) {
            const { type, fields, configurable, forceLargeModal } = objectFields[key];
            if (type === 'reference') {
                if (!this.search_modal_refs[key]) this.search_modal_refs[key] = React.createRef();
                modals.push(
                    <SearchObjectModal configuration={configuration} context={context} key={`modal_search_${key}`}
                        dataKey={key} referencedCollection={objectFields[key].references} ref={this.search_modal_refs[key]} />
                );
            } else if (type === 'array') {
                if (!this.search_modal_refs[key]) this.search_modal_refs[key] = {};
                modals = modals.concat(
                    fields.filter(f => f.type === 'reference')
                        .map((f, rownum) => {
                            if (!this.search_modal_refs[key][f.key]) this.search_modal_refs[key][f.key] = React.createRef();
                            return (
                                <SearchObjectModal configuration={configuration} context={context} key={`modal_search_${key}_${f.key}`}
                                    parentKey={key} dataKey={rownum} referencedCollection={f.references} ref={this.search_modal_refs[key][f.key]} />
                            );
                        })
                );
            } else if (type === 'map') {
                if (!this.search_modal_refs[key]) this.search_modal_refs[key] = {};
                modals = modals.concat(
                    fields.filter(f => f.type === 'reference')
                        .map((f, rownum) => {
                            if (!this.search_modal_refs[key][f.key]) this.search_modal_refs[key][f.key] = React.createRef();
                            return (
                                <SearchObjectModal configuration={configuration} context={context} key={`modal_search_${key}_${f.key}`}
                                    parentKey={key} dataKey={rownum} referencedCollection={f.references} ref={this.search_modal_refs[key][f.key]} />
                            );
                        })
                );
            }
            const configurableFields = (type === 'map' || type === 'array') && fields.filter(f => f.configOnly).length > 0;
            if (configurable || configurableFields) {
                if (!this.configure_modal_refs[key]) this.configure_modal_refs[key] = React.createRef();
                modals.push(
                    <ConfigureObjectModal configuration={configuration} objectId={this.props.objectId} context={context} key={`modal_search_${key}`}
                        dataKey={key} workingData={this.workingData} ref={this.configure_modal_refs[key]}
                        forceLargeModal={forceLargeModal} />
                );
            }
        }
        this.modals = modals;
    }
    searchReferenceObjectOCL = (key, ds_row, ds_col) => async (e) => {
        e.preventDefault();
        try {
            if (ds_row !== undefined && ds_col !== undefined) {
                const result = await this.search_modal_refs[key][ds_col].current.startSearch();
                const newObject = { ...this.state.workingData };
                newObject[key][ds_row][ds_col] = result;
                this.setState(() => ({
                    workingData: newObject,
                    refreshTs: currentTime()
                }));
            } else {
                const result = await this.search_modal_refs[key].current.startSearch();
                const newObject = { ...this.state.workingData };
                newObject[key] = result;
                this.setState(() => ({
                    workingData: newObject,
                    refreshTs: currentTime()
                }));
            }
        } catch (err) {
            //invalid_items usually mean user clicked away or closed modal early; ignore these
            if (err.code !== 'invalid_item') {
                console.error(err);
            }
        }
    };
    configureObjectOCL = (key, ds_row) => async (e) => {
        e.preventDefault();
        try {
            if (ds_row !== undefined) {
                const { selection, modified } = await this.configure_modal_refs[key].current.startConfig(this.state.workingData[key][ds_row], `${key}_${ds_row}`);
                if (modified) {
                    const newObject = { ...this.state.workingData };
                    newObject[key][ds_row] = selection;
                    this.setState(() => ({
                        workingData: newObject,
                        refreshTs: currentTime()
                    }));
                }
            } else {
                const { selection, modified } = await this.configure_modal_refs[key].current.startConfig(this.state.workingData[key], key);
                if (modified) {
                    const newObject = { ...this.state.workingData };
                    newObject[key] = selection;
                    this.setState(() => ({
                        workingData: newObject,
                        refreshTs: currentTime()
                    }));
                }
            }
        } catch (err) {
            //invalid_items usually mean user clicked away or closed modal early; ignore these
            if (err.code !== 'invalid_item') {
                console.error(err);
            }
        }
    };
    clearReferenceObjectOCL = (key, ds_row, ds_col) => async (e) => {
        e.preventDefault();
        const newObject = { ...this.state.workingData };
        if (ds_row && ds_col) {
            delete newObject[key][ds_row][ds_col];
        } else {
            delete newObject[key];
        }
        this.setState(() => ({
            workingData: newObject,
            refreshTs: currentTime()
        }));
    };
    viewReferenceObjectOCL = (key) => async (e) => {
        e.preventDefault();

    };
    formOSL = async (e) => {
        e.preventDefault();
        const { auth } = this.props.context;
        if (!auth.isLoggedIn) {
            notification("You are not logged in. Please log in and try again.", { status: "danger" });
            return;
        }
        const currentUser = auth.user.displayName;
        //validate that user has a display name set
        if (!currentUser) {
            notification("Your 'Display Name' is not set. Please set one in your User Profile.");
            return;
        }
        //validate each field
        const failedValidation = validateDataMapping({
            dataMapping: this.props.dataMapping,
            objectFields: this.props.configuration.objectFields,
            workingData: this.state.workingData
        });
        if (failedValidation.length > 0) {
            notification("Some required fields are blank: " + failedValidation.join(","), { status: 'danger' });
            return;
        }
        if (this.props.isBaseForm) {
            for (const [rowkey, rowval] of Object.entries(this.props.configuration.objectFields)) {
                //upload files if needed
                if (rowval.type === 'file') {
                    const file = this.state.workingData[rowkey];
                    if (file instanceof File) {
                        const storageRef = firebaseStorage.ref();
                        const cloudPath = `${this.props.configuration.collectionName}/${this.props.objectId}/${rowkey}/${file.name}`;
                        const fileRef = storageRef.child(cloudPath);
                        const fileMetadata = { contentType: file.type };
                        const snapshot = await fileRef.put(file, fileMetadata);
                        if (snapshot) {
                            this.state.workingData[rowkey] = {
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                cloudPath: cloudPath,
                                lastModified: timestamp.fromDate(file.lastModifiedDate),
                            };
                        } else {
                            this.state.workingData[rowkey] = this.props.workingData[rowkey];
                        }
                    }
                }
                if (rowval.type === 'array') {
                    for (const { key, type } of rowval.fields) {
                        //upload files in subfields if needed
                        if (this.state.workingData[rowkey] && type === 'file') {
                            for (let arrIdx = 0; arrIdx < this.state.workingData[rowkey].length; arrIdx++) {
                                const file = this.state.workingData[rowkey][arrIdx][key];
                                if (file instanceof File) {
                                    const storageRef = firebaseStorage.ref();
                                    const cloudPath = `${this.props.configuration.collectionName}/${this.props.objectId}/${rowkey}/${arrIdx}/${key}/${file.name}`;
                                    const fileRef = storageRef.child(cloudPath);
                                    const fileMetadata = { contentType: file.type };
                                    const snapshot = await fileRef.put(file, fileMetadata);
                                    if (snapshot) {
                                        this.state.workingData[rowkey][arrIdx][key] = {
                                            name: file.name,
                                            size: file.size,
                                            type: file.type,
                                            cloudPath: cloudPath,
                                            lastModified: timestamp.fromDate(file.lastModifiedDate),
                                        };
                                    } else {
                                        this.state.workingData[rowkey][arrIdx][key] = this.props.workingData[rowkey][arrIdx][key];
                                    }
                                }
                            }
                        }
                    }
                }
                if (rowval.type === 'map') {
                    for (const { key, type } of rowval.fields) {
                        //upload files in subfields if needed
                        if (this.state.workingData[rowkey] && type === 'file') {
                            for (const [mapkey, mapval] of Object.entries(this.state.workingData[rowkey])) {
                                if (mapval.key === null) continue;
                                const file = this.state.workingData[rowkey][mapkey][key];
                                if (file instanceof File) {
                                    const storageRef = firebaseStorage.ref();
                                    const cloudPath = `${this.props.configuration.collectionName}/${this.props.objectId}/${rowkey}/${mapkey}/${key}/${file.name}`;
                                    const fileRef = storageRef.child(cloudPath);
                                    const fileMetadata = { contentType: file.type };
                                    const snapshot = await fileRef.put(file, fileMetadata);
                                    if (snapshot) {
                                        this.state.workingData[rowkey][mapkey][key] = {
                                            name: file.name,
                                            size: file.size,
                                            type: file.type,
                                            cloudPath: cloudPath,
                                            lastModified: timestamp.fromDate(file.lastModifiedDate)
                                        };
                                    } else {
                                        this.state.workingData[rowkey][mapkey][key] = this.props.WorkingData[rowkey][mapkey][key];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        const dataFields = processDataMapping({
            nestedForm: this.props.nestedForm,
            dataMapping: this.props.dataMapping,
            objectFields: this.props.configuration.objectFields,
            workingData: this.state.workingData,
            db: firestoreDb,
        });
        await this.props.onSubmit(dataFields);
    };
    addObjectTableEntryOCL = (key) => (e) => {
        e.preventDefault();
        const workingData = { ...this.state.workingData };
        const mapData = workingData[key] || [];
        const numEntries = mapData.length;
        const newKey = `entry${numEntries}`;
        //update data
        mapData.push({ key: newKey });
        workingData[key] = mapData;
        this.setState(() => ({ workingData, refreshTs: currentTime() }));
    };
    removeObjectTableEntryOCL = (key, tableIdx) => (e) => {
        e.preventDefault();
        //delete data
        const workingData = { ...this.state.workingData };
        workingData[key].splice(tableIdx, 1);
        this.setState(() => ({ workingData, refreshTs: currentTime() }));
    };
    addObjectMapEntryOCL = (key) => (e) => {
        e.preventDefault();
        const workingData = { ...this.state.workingData };
        const mapData = workingData[key] || {};
        // const numEntries = Object.keys(mapData).length;
        //temporary. replace with better user interface
        const newKey = window.prompt('Enter key for new element (this cannot be changed later)');
        //update data
        mapData[newKey] = { key: newKey };
        workingData[key] = mapData;
        this.setState(() => ({ workingData, refreshTs: currentTime() }));
    };
    removeObjectMapEntryOCL = (key, mapKey) => (e) => {
        e.preventDefault();
        //delete data
        const workingData = { ...this.state.workingData };
        delete workingData[key][mapKey];
        this.setState(() => ({ workingData, refreshTs: currentTime() }));
    };
    updateField = (key) => (e) => {
        const workingData = { ...this.state.workingData };
        let value = e.target.value;
        if (e.target.type === 'checkbox') {
            value = !!e.target.checked;
        } else if (e.target.type === 'number') {
            value = Number(e.target.value);
        } else if (e.target.type === 'datetime-local') {
            value = timestamp.fromDate(new Date(e.target.value + 'Z'));
        } else if (e.target.type === 'file') {
            const file = e.target.files[0];
            value = file;
        }
        workingData[key] = value;
        this.setState(() => ({ workingData, refreshTs: currentTime() }));
    };
    updateNestedField = (key, rowKey, colKey) => (e) => {
        const workingData = { ...this.state.workingData };
        let value = e.target.value;
        if (e.target.type === 'checkbox') {
            value = !!e.target.checked;
        } else if (e.target.type === 'number') {
            value = Number(e.target.value);
        } else if (e.target.type === 'datetime-local') {
            value = timestamp.fromDate(new Date(e.target.value + 'Z'));
        } else if (e.target.type === 'file') {
            const file = e.target.files[0];
            value = file;
        }
        workingData[key][rowKey][colKey] = value;
        this.setState(() => ({ workingData, refreshTs: currentTime() }));
    };
    updateQuillField = (key) => (quillContents) => {
        const workingData = { ...this.state.workingData };
        const stringOps = JSON.stringify(quillContents);
        if (stringOps !== workingData[key]) {
            workingData[key] = stringOps;
            this.setState(() => ({ workingData }));
        }
    };
    clearFile = (key) => async (e) => {
        e.preventDefault();
        const workingData = { ...this.state.workingData, [key]: null };
        this.setState(() => ({ workingData }));
    };
    downloadFile = (key) => async (e) => {
        e.preventDefault();
        const fileRec = this.state.workingData[key];
        try {
            const url = await this.props.context.storage.ref(fileRec.cloudPath).getDownloadURL();
            window.open(url);
        } catch (err) {
            if (err.code_ === 'storage/object-not-found') {
                this.props.context.notification('Error: Object not found', { status: 'danger' });
            } else {
                console.error(err);
                this.props.context.notification('Error while fetching object', { status: 'danger' });
            }
        }
    };
    render() {
        const { dataMapping, configuration } = this.props;
        const { objectFields } = configuration;
        const formType = !!this.props.stackedForm ? 'uk-form-stacked' : 'uk-form-horizontal'
        const formWidth = !!this.props.twoColumns ? 'uk-column-1-2@l uk-column-divider' : '';
        const workingData = this.state.workingData;
        const allowed = typeof configuration.allowed === 'function' ? configuration.allowed({ view: 'form', formMode: this.props.mode, data: this.state.workingData, user: this.props.context.auth.user }) : configuration.allowed;
        return <>
            <form className={`${formType} ${formWidth}`} onSubmit={this.formOSL}>
                {dataMapping
                    //skip over hidden fields
                    .filter(({ showFieldFn }) => !showFieldFn || showFieldFn(workingData))
                    .map(({ key, required, render }) => {
                        //support render functions in dataMappings
                        let workingDataValue = workingData[key] || '';
                        if (render) workingDataValue = render(workingDataValue);
                        const { label, type, min, max, step, fields,
                            expandByDefault, listEntries, readOnly, pathPrefix } = objectFields[key];
                        const inputFieldClass = readOnly ? 'uk-input uk-form-blank' : 'uk-input';
                        const reactKey = `edit_object_form_control_${key}`;
                        if (type === "money") {
                            // const display = (typeof workingDataValue === 'number') ? workingDataValue.toFixed(2) : workingDataValue;
                            return (
                                <LabeledControl id={`${key}_input`} key={reactKey} label={label} className="uk-margin avoid-column-break" required={!!required}>
                                    <input className={inputFieldClass} value={workingDataValue} onChange={this.updateField(key)}
                                        readOnly={readOnly} type="number" min={min} max={max} step={0.01} />
                                </LabeledControl>
                            );
                        } else if (type === "number") {
                            return (
                                <LabeledControl id={`${key}_input`} key={reactKey} label={label} className="uk-margin avoid-column-break" required={!!required}>
                                    <input className={inputFieldClass} value={workingDataValue} onChange={this.updateField(key)}
                                        readOnly={readOnly} type="number" min={min} max={max} step={step} />
                                </LabeledControl>
                            );
                        } else if (type === "file") {
                            const fileSize = workingDataValue?.size;
                            return (
                                <LabeledControl id={`${key}_input`} key={reactKey} label={label} className="uk-margin avoid-column-break" required={!!required}>
                                    <div>
                                        <div uk-grid='true' uk-form-custom='target: true'>
                                            <div className="uk-width-expand">
                                                <input type="file" onChange={this.updateField(key)} />
                                                <input className={"uk-input uk-form-blank"} defaultValue={workingDataValue?.name} disabled={true} type="text" />
                                            </div>
                                            <div className='uk-padding-remove' style={{ marginLeft: "5px" }}>
                                                <button className="uk-button uk-button-default" type="button" tabIndex={-1}>Browse</button>
                                            </div>
                                        </div>
                                        <div className='uk-flex'>
                                            {fileSize &&
                                                <span className={'uk-margin-small-right'}>{humanFileSize(fileSize)}</span>}
                                            {workingDataValue.cloudPath &&
                                                <button className='uk-button uk-button-link uk-margin-small-right' onClick={this.downloadFile(key)}>Download</button>}
                                            <button className='uk-button uk-button-link' onClick={this.clearFile(key)}>Clear</button>
                                        </div>
                                    </div>
                                </LabeledControl>
                            );
                        } else if (type === "timestamp") {
                            const display = workingDataValue ? dateFromTimestamp(workingDataValue)?.toISOString().slice(0, -1) : undefined;
                            return (
                                <LabeledControl id={`${key}_input`} key={reactKey} label={label} className="uk-margin avoid-column-break" required={!!required}>
                                    <input className={inputFieldClass} value={display} onChange={this.updateField(key)}
                                        readOnly={readOnly} type="datetime-local" min={min} max={max} step={step} />
                                </LabeledControl>
                            );
                        } else if (type === "boolean") {
                            return (
                                <LabeledControl id={`${key}_input`} key={reactKey} label={label} className="uk-margin avoid-column-break" required={!!required}>
                                    <input className="uk-checkbox" checked={!!workingDataValue} onChange={this.updateField(key)}
                                        readOnly={readOnly} type="checkbox" />
                                </LabeledControl>
                            );
                        } else if (type === "quill") {
                            const defaultValue = workingDataValue ? JSON.parse(workingDataValue) : [];
                            const liClass = expandByDefault ? 'uk-open' : '';
                            return (
                                <ul uk-accordion='animation: false' key={reactKey} className="uk-margin-small-bottom avoid-column-break">
                                    <li className={liClass}>
                                        <span className="uk-accordion-title uk-link">{label}</span>
                                        <div className="uk-accordion-content">
                                            <div className="uk-margin-bottom avoid-column-break" key={reactKey}>
                                                <QuillField className="uk-margin-bottom" id={`${key}_input`} context={this.props.context}
                                                    lastUpdated={this.state.refreshTs} readOnly={false} content={defaultValue}
                                                    changeHandler={this.updateQuillField(key)} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            );
                        } else if (type === "dropdown") {
                            return (
                                <LabeledControl id={`${key}_input`} key={reactKey} label={label} className="uk-margin avoid-column-break" required={!!required}>
                                    <select className="uk-select" value={workingDataValue} readOnly={readOnly} onChange={this.updateField(key)}>
                                        {listEntries.map(({ label, value }) => (
                                            <option key={value} value={value}>{label}</option>
                                        ))}
                                    </select>
                                </LabeledControl>
                            );
                        } else if (type === "map") {
                            const columnMappings = fields
                                .filter(field => !field.configOnly)
                                .reduceRight((acc, field) => {
                                    const input = (row) => {
                                        const inputClass = field.readOnly ? 'uk-input uk-form-blank' : 'uk-input';
                                        const defaultValue = row[field.key] || '';
                                        const min = field.min === undefined ? -999_999_999_999 : field.min;
                                        if (field.type === 'delete') {
                                            return <button className="uk-icon-button uk-align-center"
                                                uk-icon="icon: trash;" title="Delete Object"
                                                onClick={this.removeObjectMapEntryOCL(key, row.key)} />;
                                        } else if (field.type === 'config') {
                                            return <button className="uk-icon-button uk-align-center"
                                                uk-icon="icon: cog;" title="Configure Object"
                                                onClick={this.configureObjectOCL(key, row.key)} />;
                                        } else if (field.type === 'boolean') {
                                            return <input type="checkbox" className="uk-checkbox uk-align-center uk-margin-small-top"
                                                checked={!!defaultValue} onChange={this.updateNestedField(key, row.key, field.key)} />;
                                        } else if (field.type === 'number') {
                                            return <input className={inputClass} value={defaultValue} type="number"
                                                min={min} max={field.max} step={field.step} readOnly={field.readOnly}
                                                onChange={this.updateNestedField(key, row._key, field.key)} />;
                                        } else if (field.type === 'money') {
                                            const moneyInput = Number(defaultValue && Number(defaultValue).toFixed(2)) || '';
                                            return <input className={inputClass} value={moneyInput} type="number"
                                                min={min} max={field.max} step={0.01} readOnly={field.readOnly}
                                                onChange={this.updateNestedField(key, row._key, field.key)} />;
                                        } else if (field.type === 'reference') {
                                            const refDefaultValue = defaultValue ? defaultValue.name || defaultValue.code || defaultValue.description : '';
                                            return <div className='uk-inline'>
                                                <p>
                                                    {refDefaultValue}
                                                    <button className='uk-icon-link uk-margin-small-left' uk-icon='search' title='Search Object'
                                                        onClick={this.searchReferenceObjectOCL(key, row.key, field.key)} />
                                                </p>
                                            </div>;
                                        } else {
                                            return <input className={inputClass} value={defaultValue} style={{ minWidth: '7em' }}
                                                required={!!field.required} readOnly={field.readOnly}
                                                onChange={this.updateNestedField(key, row.key, field.key)} />;
                                        }
                                    };
                                    return Object.assign({ [field.label]: input }, acc);
                                }, {});
                            const tableData = Object.values(workingDataValue || {}).filter((v) => !!v?.key);
                            const liClass = expandByDefault ? 'uk-open' : '';
                            return (
                                <ul uk-accordion='animation: false' key={reactKey} className="uk-margin-small-bottom avoid-column-break">
                                    <li className={liClass}>
                                        <span className="uk-accordion-title uk-link">{label}</span>
                                        <div className="uk-accordion-content">
                                            <div className="uk-overflow-auto uk-width-1-1">
                                                <DividedTable id={`${key}_table`} data={tableData}
                                                    animate={false} columnMappings={columnMappings}
                                                    className='uk-table-small' />
                                            </div>
                                            <button onClick={this.addObjectMapEntryOCL(key)} title='Add Object'
                                                className="uk-button uk-button-default">Add Entry</button>
                                        </div>
                                    </li>
                                </ul>
                            );
                        } else if (type === "array") {
                            const columnMappings = fields
                                .filter(field => !field.configOnly)
                                .reduceRight((acc, field) => {
                                    const input = (row, rowNum) => {
                                        const defaultValue = workingData[key][rowNum][field.key] || '';
                                        const inputClass = field.readOnly ? 'uk-input uk-form-blank' : 'uk-input';
                                        const min = field.min === undefined ? -999_999_999_999 : field.min;
                                        if (field.type === 'delete') {
                                            return <button className="uk-icon-button uk-align-center"
                                                uk-icon="icon: trash;" title="Delete Object"
                                                onClick={this.removeObjectTableEntryOCL(key, rowNum)} />;
                                        } else if (field.type === 'config') {
                                            return <button className="uk-icon-button uk-align-center"
                                                uk-icon="icon: cog;" title="Configure Object"
                                                onClick={this.configureObjectOCL(key, rowNum)} />;
                                        } else if (field.type === 'boolean') {
                                            return <input type="checkbox" className="uk-checkbox uk-align-center uk-margin-small-top"
                                                checked={!!defaultValue} onChange={this.updateNestedField(key, rowNum, field.key)} />;
                                        } else if (field.type === 'number') {
                                            return <input className={inputClass} readOnly={field.readOnly} value={defaultValue} onChange={this.updateNestedField(key, rowNum, field.key)}
                                                type="number" min={min} max={field.max} step={field.step} />;
                                        } else if (field.type === 'money') {
                                            const moneyInput = Number(defaultValue && Number(defaultValue).toFixed(2)) || '';
                                            return <input className={inputClass} readOnly={field.readOnly} value={moneyInput} onChange={this.updateNestedField(key, rowNum, field.key)}
                                                type="number" min={min} max={field.max} step={0.01} />;
                                        } else if (field.type === 'reference') {
                                            const refDefaultValue = defaultValue ? defaultValue.name || defaultValue.code || defaultValue.description : '';
                                            return <div className='uk-inline'>
                                                <p>
                                                    {refDefaultValue}
                                                    <button className='uk-icon-link uk-margin-small-left' uk-icon='search' title='Search Object'
                                                        onClick={this.searchReferenceObjectOCL(key, rowNum, field.key)} />
                                                </p>
                                            </div>;
                                        } else {
                                            return <input className={inputClass} readOnly={field.readOnly} value={defaultValue} style={{ minWidth: '7em' }}
                                                required={!!field.required} onChange={this.updateNestedField(key, rowNum, field.key)} />;
                                        }
                                    };
                                    return Object.assign({ [field.label]: input }, acc);
                                }, {});
                            const tableData = (workingDataValue || []);
                            const liClass = expandByDefault ? 'uk-open' : '';
                            return (
                                <ul uk-accordion='animation: false' key={reactKey} className="uk-margin-small-bottom avoid-column-break">
                                    <li className={liClass}>
                                        <span className="uk-accordion-title uk-link">{label}</span>
                                        <div className="uk-accordion-content">
                                            <div className="uk-overflow-auto uk-width-1-1">
                                                <DividedTable id={`${key}_table`} data={tableData}
                                                    animate={false} columnMappings={columnMappings}
                                                    className='uk-table-small' />
                                            </div>
                                            <button onClick={this.addObjectTableEntryOCL(key)} title='Add Object'
                                                className="uk-button uk-button-default">Add Entry</button>
                                        </div>
                                    </li>
                                </ul>
                            );
                        } else if (type === "reference") {
                            const currentValue = workingDataValue?.name || '';
                            return (
                                <LabeledControl id={`${key}_input`} key={reactKey} label={label} className="uk-margin avoid-column-break" required={!!required}>
                                    <div>
                                        <div uk-grid="true">
                                            <div className="uk-width-expand">
                                                <input className="uk-input uk-form-blank" value={currentValue} disabled={true} />
                                            </div>
                                            <div style={{ paddingLeft: "5px" }}>
                                                <button className="uk-button uk-button-default" onClick={this.searchReferenceObjectOCL(key)} title="Search">Search</button>
                                            </div>
                                        </div>
                                        <div className='uk-flex'>
                                            {workingDataValue.name &&
                                                <Link className='uk-button uk-button-link uk-margin-small-right' target='_blank'
                                                    to={`${pathPrefix}/${workingDataValue.id}`}>View</Link>}
                                            {workingDataValue.name &&
                                                <button className='uk-button uk-button-link' onClick={this.clearReferenceObjectOCL(key)}>Clear</button>}
                                        </div>
                                    </div>
                                </LabeledControl>
                            );
                        } else {
                            return (
                                <LabeledControl id={`${key}_input`} key={reactKey} label={label} className="uk-margin avoid-column-break" required={!!required}>
                                    <input className={inputFieldClass} readOnly={readOnly} value={workingDataValue}
                                        onChange={this.updateField(key)} type="text" />
                                </LabeledControl>
                            );
                        }
                    })}
                <div className="uk-flex uk-flex-center">
                    {allowed?.update && (
                        <input type="submit" className="uk-button uk-button-secondary" title="Save" value="Save" />)}
                    {this.props.extraButtons}
                </div>
            </form>
            {this.modals}
        </>;
    }
}
export default withContext(DynamicObjectForm);