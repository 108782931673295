import React from 'react';
import NewCampaignWorkflow from '../../workflows/custom/campaign/NewCampaignWorkflow'
// import { Breadcrumb, BreadcrumbSectionContainer } from '../../components/Util';
// import CreateObjectView from '../../components/core/object/views/CreateObjectView';

// const NewCampaign = ({ context, configuration, location ,pathRoot }) => {
//     const { darkTheme } = context;
//     const sectionThemeClass = darkTheme ? 'uk-section-secondary' : 'uk-section-default';
//     return <>
//         <BreadcrumbSectionContainer>
//             <Breadcrumb label={'New Campaign'} parentLinkMappings={{
//                 'Campaigns': `/campaign`
//             }} />
//         </BreadcrumbSectionContainer>
//         <div className={`uk-section ${sectionThemeClass} uk-animation-small-slide-top`}>
//             <CreateObjectView location={location} pathRoot={pathRoot}
//                 hideCloseButton={true} configuration={configuration} />
//         </div>
//     </>;
// }

// export default NewCampaign;

export default function NewCampaign ({ location, pathRoot }) {
    return <>
        <div className='uk-section uk-section-default'>
            <div className='uk-container uk-container-small'>
                <NewCampaignWorkflow location={location} pathRoot={pathRoot} />
            </div>
        </div>
    </>
}