import React, { useState, useEffect } from 'react';
import { withContext } from '../../../appState';
import WorkflowStateMachine, { WorkflowStateMessage } from './WorkflowStateMachine';

const LoadingErrorDiv = () => <WorkflowStateMessage title='Workflow Error' message='Encountered an error while loading this workflow. Please try again later.' />;

const LoadingDiv = () => (
    <div className='uk-container-small uk-text-center'>
        <h2>Loading Workflow</h2>
        <div uk-spinner='true' />
    </div>
);

function FirestoreObjectStateMachine({
    location, pathRoot, context,
    children, validateTransitions = false,
    stateTransitionMap = {}, initialState = null,
    collectionName, objectId,
}) {
    const collection = context[collectionName];
    const [_objectId, setPrivateObjectId] = useState(objectId);
    const [latestObject, setLatestObject] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        (async () => {
            try {
                const object = await collection.getObject(objectId, false);
                setLatestObject(object);
            } catch (e) {
                if (e.code === 'no_data') {
                    setPrivateObjectId(await collection.updateObject(objectId, {}));
                    setLatestObject({});
                } else {
                    setError(true);
                }
            }
            setLoaded(true);
        })();
    }, [collection, objectId]);
    const updateObject = async (fields) => {
        if (collection.updateObject(_objectId, fields)) {
            setLatestObject(await collection.getObject(objectId));
        }
    }
    if (error) {
        return <LoadingErrorDiv />;
    }
    if (loaded) {
        return (
            <WorkflowStateMachine
                location={location} pathRoot={pathRoot} context={context} validateTransitions={validateTransitions}
                stateTransitionMap={stateTransitionMap} initialState={initialState}
                mixins={{
                    updateObject: updateObject,
                    latestObject: latestObject,
                }}
            >
                {children}
            </WorkflowStateMachine>
        );
    } else {
        return <LoadingDiv />;
    }
}

export default withContext(FirestoreObjectStateMachine);