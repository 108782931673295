import React from 'react';
import { Breadcrumb, BreadcrumbSectionContainer } from '../../components/Util';
import TableObjectView from '../../components/core/object/views/TableObjectView';

const MyCampaigns = ({ context, location, pathRoot, configuration }) => {
    const { darkTheme } = context;
    const sectionThemeClass = darkTheme ? 'uk-section-secondary' : 'uk-section-default';
    return <>
        <BreadcrumbSectionContainer>
            <Breadcrumb label={'My Campaigns'} parentLinkMappings={{
                'Campaigns': `/campaign`
            }} />
        </BreadcrumbSectionContainer>
        <div className={`uk-section ${sectionThemeClass} uk-animation-small-slide-top`}>
            <TableObjectView location={location} animate={true} viewType='user'
                className='uk-margin-top' pathRoot={pathRoot} configuration={configuration} />
        </div>
    </>;
}

export default MyCampaigns;