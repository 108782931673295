import AuthenticationStore from './AuthenticationStore';
import SystemStore from './SystemStore';
import UserAdminStore from './UserAdminStore';
import CampaignStore from './CampaignStore';

class RootStore {
    auth = new AuthenticationStore(this);
    system = new SystemStore(this);
    userAdmin = new UserAdminStore(this);
    campaign = new CampaignStore(this);
    
    //react-router history and related methods
    history = null;
    changeRoute = (newRoute) => {
        if(this.history) {
            this.history.push(newRoute);
        }
    };
    goBack = () => {
        if(this.history) {
            this.history.goBack();
        }
    };
}

export default RootStore;