import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { notification } from 'uikit';

import { timestamp } from '../../../../firebase';
import { withContext } from '../../../../appState';

import DynamicObjectForm from '../DynamicObjectForm';

class EditableObjectView extends PureComponent {
    form_refs = [];
    modal_refs = [];
    modals = [];
    state = {
        category: null,
        loading: false,
        workingData: null,
        data: null
    };
    async componentDidMount() {
        const { objectId, configuration, context } = this.props;
        const { firestoreCollectionService, objectFields } = configuration;
        this.setState(() => ({ loading: true }));
        let data = await firestoreCollectionService.getObject(objectId);
        //shallow copy
        data = { ...data };
        //get reference object keys
        const refObjects = Object.keys(objectFields)
            .filter((key) => objectFields[key].type === 'reference' && data[key] && data[key].id);
        const arrayObjects = Object.keys(objectFields)
            .filter((key) => objectFields[key].type === 'array' && data[key]);
        const mapObjects = Object.keys(objectFields)
            .filter((key) => objectFields[key].type === 'map' && data[key]);
        //fetch reference data
        for (const refKey of refObjects) {
            const objectId = data[refKey].id;
            const referencedService = context.services[objectFields[refKey].references];
            data[refKey] = await referencedService.getObject(objectId);
        }
        //fetch foreign reference data
        for (const key of arrayObjects) {
            for (const field of objectFields[key].fields) {
                if (field.type === 'reference') {
                    const referencedService = context.services[field.references];
                    for (const rowIdx in data[key]) {
                        const objectId = data[key][rowIdx][field.key].id;
                        data[key][rowIdx][field.key] = await referencedService.getObject(objectId);
                    }
                }
            }
        }
        for (const key of mapObjects) {
            for (const rowKey of Object.keys(data[key])) {
                for (const field of objectFields[key].fields) {
                    if (data[key][rowKey][field.key] && field.type === 'reference') {
                        const referencedService = context.services[field.references];
                        const objectId = data[key][rowKey][field.key].id;
                        data[key][rowKey][field.key] = await referencedService.getObject(objectId);
                    }
                }
            }
        }
        this.setState(() => ({ loading: false, workingData: data, data }));
        //scroll to top
        window.scrollTo(0, 0);
    }
    deleteButtonOCL = async (e) => {
        e.preventDefault();
        const { objectId, configuration, context, location, pathRoot } = this.props;
        const { firestoreCollectionService } = configuration;
        if (!context.auth.isLoggedIn) {
            notification("You are not logged in. Please log in and try again.", { status: "danger" });
            return;
        }
        if (window.confirm("Are you sure you want to delete this record?")) {
            this.setState(() => ({ loading: true }));
            notification("Delete in progress...");
            try {
                await firestoreCollectionService.deleteObject(objectId);
                this.setState(() => ({ loading: false }));
                context.changeRoute(`/${pathRoot}${location.search}`);
                notification.closeAll();
                notification("Delete successful.", { status: 'success' });
            } catch (err) {
                this.setState(() => ({ loading: false }));
                notification("Delete error.", { status: 'danger' });
                console.warn(err);
            }
        }
    };
    updateObjectOSL = async (dataFields) => {
        const { objectId, context, location, pathRoot, configuration } = this.props;
        const { firestoreCollectionService } = configuration;
        const { auth } = context;

        //useful for search
        if (dataFields.name) dataFields.nameLowerCase = dataFields.name.toLowerCase();
        //audit fields
        dataFields.updatedByUser = auth.user.uid;
        dataFields.updatedBy = auth.user.displayName;
        dataFields.updatedOn = timestamp.now();
        //fire off the update call
        this.setState(() => ({ loading: true }));
        notification("Update in process...");
        try {
            await firestoreCollectionService.updateObject(objectId, dataFields, false);
            const newData = await firestoreCollectionService.getObject(objectId);
            this.setState(() => ({ loading: false, data: newData, workingData: newData }));
            context.changeRoute(`/${pathRoot}/${objectId}${location.search}`);
            notification.closeAll();
            notification("Update successful.", { status: 'success' });
        } catch (err) {
            this.setState(() => ({ loading: false }));
            notification("Update error.", { status: 'danger' });
            console.warn(err);
        }
    }
    render() {
        const { objectId, mode, location, pathRoot, configuration, hideCloseButton = false } = this.props;
        const { objectName, twoColumns, editDataMapping, viewDataTransform } = configuration;
        const { data, workingData } = this.state;
        //return children({props:this.props, state:this.state});
        const CloseButton = () => (
            <Link to={`/${pathRoot}${location.search}`} title={`Close ${objectName}`} className="uk-icon-button uk-margin-small-right" uk-icon="icon: close" />
        );
        const containerWidth = twoColumns ? 'uk-container' : 'uk-container uk-container-small';
        const containerClassName = 'uk-margin-bottom uk-animation-slide-top-small';
        const editMode = (mode === "edit"); //all other passed values are considered "view"
        const allowed = typeof configuration.allowed === 'function' ? configuration.allowed({ view: 'edit', data: this.state.data, user: this.props.context.auth.user }) : configuration.allowed;
        if (data) {
            const viewLink = `/${pathRoot}/${objectId}${location.search}`;
            const editLink = `/${pathRoot}/${objectId}/edit${location.search}`;
            if (editMode) {
                return (
                    <div className={`${containerClassName} ${containerWidth}`} id="singleObjectEditPage">
                        <div className="uk-margin">
                            {!hideCloseButton && <CloseButton />}
                            {allowed?.read && (
                                <Link to={viewLink} className="uk-icon-button uk-margin-small-right" uk-tooltip={`View ${objectName}`} uk-icon="icon: file-text" /> )}
                            {allowed?.delete && (
                                <button className="uk-icon-button" uk-icon="icon: trash" uk-tooltip={`Delete ${objectName}`} onClick={this.deleteButtonOCL} />)}
                        </div>
                        <DynamicObjectForm dataMapping={editDataMapping} objectId={objectId} isBaseForm={true}
                            workingData={workingData} configuration={configuration} mode='edit'
                            twoColumns={twoColumns} onSubmit={this.updateObjectOSL} />
                    </div>
                );
            } else {
                return (
                    <div className={`${containerClassName} uk-container uk-container-large`} id="singleObjectViewPage">
                        <div className="uk-margin">
                            {!hideCloseButton && <CloseButton />}
                            {allowed?.update && (
                                <Link to={editLink} className="uk-icon-button" uk-icon="icon: pencil" uk-tooltip={`Edit ${objectName}`} /> )}
                        </div>
                        {viewDataTransform(data)}
                    </div>
                );
            }
        } else {
            return (
                <div className={`${containerClassName} uk-container uk-container-small`} id="singleObjectViewPage">
                    {!hideCloseButton && <CloseButton />}
                    <p>{this.state.loading ? "Loading" : "No Data"}</p>
                </div>
            );
        }
    }
}

export default withContext(EditableObjectView);