import { makeObservable, observable } from 'mobx';
import AuthenticatedFunctionService from '../services/AuthenticatedFunctionService';

const serviceConfig = {
    serviceName: 'UserAdminStore',
};
const storeConfig = {
    store: false,
    siteUrl: false,
    responseCache: observable,
};

class UserAdminStore extends AuthenticatedFunctionService {
    store = null;
    responseCache = new Map();
    constructor(store) {
        super(serviceConfig);
        makeObservable(this, storeConfig);
        this.store = store;
    }

    get siteUrl() {
        return this.store.system?.site;
    }

    getIdToken = () => {
        return this.store.auth.idToken;
    };

    invalidateUserCache = (uid) => {
        if (this.responseCache.has(uid)) {
            this.responseCache.delete(uid);
            this.responseCache.delete('_list');
        }
    };
    updateUserCache = (uid, object) => {
        this.responseCache.set(uid, object);
        this.responseCache.delete('_list');
    };

    getUsersList = async (useCache = true) => {
        if (useCache && this.responseCache.has('_list')) {
            return this.responseCache.get('_list');
        }
        const res = await this.secureFetch(`${this.siteUrl}/api/v1/user`);
        const data = await res.json();
        this.responseCache.set('_list', data);
        for (const user of data) {
            this.responseCache.set(user.uid, user);
        }
        return data;
    };

    getUser = async (uid, useCache = true) => {
        if (useCache && this.responseCache.has(uid)) {
            return this.responseCache.get(uid);
        }
        const res = await this.secureFetch(`${this.siteUrl}/api/v1/user?uid=${uid}`);
        const data = await res.json();
        this.responseCache.set(uid, data);
        return data;
    };

    updateUser = async (uid, userData) => {
        const res = await this.secureFetch(`${this.siteUrl}/api/v1/user`, {
            method: 'POST',
            body: JSON.stringify({ uid: uid, ...userData }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await res.json();
        this.updateUserCache(uid, data);
        return data;
    };

    createUser = async (userData) => {
        const res = await this.secureFetch(`${this.siteUrl}/api/v1/user/create`, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await res.json();
        this.updateUserCache(data.uid, data);
        return data;
    };

    grantUserAdminRights = async (uid, grant = true) => {
        const res = await this.secureFetch(`${this.siteUrl}/api/v1/user/adminRights`, {
            method: 'POST',
            body: JSON.stringify({ uid, grant }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const text = await res.text();
        this.invalidateUserCache(uid);
        return text;
    };

    verifyEmail = async (uid, verify = true) => {
        const res = await this.secureFetch(`${this.siteUrl}/api/v1/user/emailVerification`, {
            method: 'POST',
            body: JSON.stringify({ uid, verify }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const text = await res.text();
        this.invalidateUserCache(uid);
        return text;
    };

    createUserPublic = async (userData, recaptcha) => {
        const res = await fetch(`${this.siteUrl}/api/public/v1/user/create`, {
            method: 'POST',
            body: JSON.stringify({
                user: userData,
                recaptcha: recaptcha
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await res.json();
        return data;
    };
}

export default UserAdminStore;