import React, { PureComponent } from 'react';

import DynamicObjectForm from '../DynamicObjectForm';
import { ModalError } from './Modal';

class ConfigureObjectModal extends PureComponent {
    modal_ref = React.createRef();
    state = {
        selectedObject: {},
        originalData: {},
        formKey: 'configure_object_modal_default',
        modified: false
    };
    startConfig = async (objectData, key) => {
        const { context } = this.props;
        const { modal } = context;
        const modalElement = modal(this.modal_ref.current);
        this.setState(() => ({
            selectedObject: { ...objectData },
            originalData: objectData,
            formKey: `${key}_${(new Date()).getTime()}`,
            modified: false
        }), () => {
            modalElement.show();
        });
        return new Promise((resolve, reject) => {
            this.modal_ref.current.addEventListener('hidden', () => {
                const modified = this.state.modified;
                const selection = modified ? this.state.selectedObject : this.state.originalData;
                if (selection) {
                    resolve({ selection, modified });
                } else {
                    reject(new ModalError('invalid_item', 'Expected to receive result from modal but received nothing'));
                }
            });
        });
    };
    finishConfig = async (newData) => {
        const { context } = this.props;
        const modalElement = context.modal(this.modal_ref.current);
        this.setState(() => ({ selectedObject: newData, modified: true }), () => {
            modalElement.hide();
        });
    };
    render() {
        const { parentKey, dataKey, configuration, objectId } = this.props;
        const { objectName, objectFields } = configuration;
        const containerClass = (this.props.forceLargeModal&&!this.props.nestedForm) ? 'uk-modal-container' : '';
        const twoColumns = (this.props.forceLargeModal&&!this.props.nestedForm);
        const field = parentKey ? objectFields[parentKey].fields[dataKey] : objectFields[dataKey];
        const fields = field.fields.filter(f => f.key);
        const newConfig = {
            collectionName: configuration.collectionName,
            allowed: {
                update: true
            },
            objectFields: fields.reduce((acc, f) => {
                return Object.assign({ [f.key]: f }, acc);
            }, {})
        };
        return (
            <div id={`configure${objectName}Modal`} className={containerClass} uk-modal="stack: true; bg-close: false;" ref={this.modal_ref}>
                <div className="uk-modal-dialog uk-modal-body">
                    <h4>Configure {field.label}</h4>
                    <DynamicObjectForm dataMapping={fields} objectId={objectId}
                        workingData={this.state.selectedObject} configuration={newConfig}
                        formKey={this.state.formKey} nestedForm={true} twoColumns={twoColumns}
                        onSubmit={this.finishConfig} stackedForm={true} extraButtons={(
                            <button className="uk-button uk-button-default uk-margin-left uk-modal-close">Close</button>
                        )} />
                </div>
            </div>
        );
    }
}

export default ConfigureObjectModal;