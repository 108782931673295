import React, { PureComponent } from 'react';
import { notification } from 'uikit';
import { firebaseAuth } from '../firebase';
import { auth_labels } from '../resources/labels';
import { LabeledControl } from './Util';

const iconStyle = { width: '1.1em', marginRight: '1em', marginBottom: '0.3em' };

const GoogleIcon = () => (
  <svg viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg" style={iconStyle}>
    <path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4" />
    <path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853" />
    <path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04" />
    <path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335" />
  </svg>
);

const AppleIcon = ({ fill = 'black' }) => (
  <svg className='appleIcon' viewBox="0 0 1000 1187.198" style={{ ...iconStyle, width: '1em', fill: fill }}>
    <path d="m 979.04184,925.18785 c -17.95397,41.47737 -39.20563,79.65705 -63.82824,114.75895 -33.56298,47.8528 -61.04356,80.9761 -82.22194,99.3698 -32.83013,30.192 -68.00529,45.6544 -105.67203,46.5338 -27.04089,0 -59.6512,-7.6946 -97.61105,-23.3035 -38.08442,-15.5358 -73.08371,-23.2303 -105.08578,-23.2303 -33.56296,0 -69.55888,7.6945 -108.06101,23.2303 -38.5608,15.6089 -69.62484,23.7432 -93.37541,24.5493 -36.12049,1.5389 -72.1237,-14.3632 -108.06101,-47.7796 -22.93711,-20.0059 -51.62684,-54.3017 -85.99592,-102.8874 C 92.254176,984.54592 61.937588,924.38175 38.187028,855.7902 12.750995,781.70252 0,709.95986 0,640.50361 0,560.94181 17.191859,492.32094 51.626869,434.81688 78.689754,388.62753 114.69299,352.19192 159.75381,325.44413 c 45.06086,-26.74775 93.74914,-40.37812 146.18212,-41.25019 28.68971,0 66.3125,8.8744 113.06613,26.31542 46.62174,17.49964 76.55727,26.37404 89.68198,26.37404 9.8124,0 43.06758,-10.37669 99.4431,-31.06405 53.31237,-19.18512 98.30724,-27.12887 135.16787,-23.99975 99.8828,8.06098 174.92313,47.43518 224.82789,118.37174 -89.33023,54.12578 -133.51903,129.93556 -132.63966,227.18753 0.8061,75.75115 28.28668,138.78795 82.2952,188.8393 24.47603,23.23022 51.81008,41.18421 82.22186,53.93522 -6.59525,19.12648 -13.557,37.44688 -20.95846,55.03446 z M 749.96366,23.751237 c 0,59.37343 -21.69138,114.810233 -64.92748,166.121963 -52.17652,60.99961 -115.28658,96.24803 -183.72426,90.68597 -0.87204,-7.12298 -1.37769,-14.61967 -1.37769,-22.49743 0,-56.99843 24.81315,-117.99801 68.87738,-167.873453 21.99909,-25.25281 49.978,-46.25018 83.90738,-63.00018 C 686.57507,10.688027 718.59913,1.5631274 748.71783,5.2734376e-4 749.59727,7.9378274 749.96366,15.875627 749.96366,23.750467 Z" id="path4" />
  </svg>
);

export class GoogleLogin extends PureComponent {
  googleOCL = async (e) => {
    const { context, pathname } = this.props;
    const { signInWithGoogle } = context.auth;
    try {
      const redirect = pathname === '/login' || pathname === '/signUp';
      await signInWithGoogle(redirect);
      notification('Login Successful', { status: 'success' });
    } catch ({ code }) {
      if (code !== 'auth/popup-closed-by-user') {
        notification('Error Occurred during Google Login', { status: 'danger' });
        console.warn(code);
      }
    }
  };
  render() {
    const { buttonText = 'Sign in with Google' } = this.props;
    return (
      <button onClick={this.googleOCL} className='uk-button uk-button-default' style={{ textTransform: 'none', height: '3em' }}>
        <GoogleIcon />
        {buttonText}
      </button>
    );
  }
}

export class AppleLogin extends PureComponent {
  appleOCL = async (e) => {
    notification('Apple Login is not yet implemented');
  };
  render() {
    const { buttonText = 'Sign in with Apple', context } = this.props;
    const { darkTheme } = context;
    return (
      <button onClick={this.appleOCL} className='uk-button uk-button-default' style={{ textTransform: 'none', height: '3em' }}>
        <AppleIcon fill={darkTheme ? 'white' : 'black'} />
        {buttonText}
      </button>
    );
  }
}

class LoginPage extends PureComponent {
  email_input_ref = React.createRef();
  password_input_ref = React.createRef();
  rem_device_input_ref = React.createRef();
  componentDidMount() {
    console.log(this.props);
  }
  submitLoginForm = async (e) => {
    e.preventDefault();
    const { context, location } = this.props;
    const { changeRoute } = context;
    const email = this.email_input_ref.current.value;
    const password = this.password_input_ref.current.value;
    const rem_device = this.rem_device_input_ref.current.checked;
    if (email && password) {
      try {
        if (!rem_device) {
          await firebaseAuth().setPersistence(firebaseAuth.Auth.Persistence.SESSION);
        }
        await firebaseAuth().signInWithEmailAndPassword(email, password);
        notification('Login Successful', { status: 'success' });
        if (location.pathname === '/login') {
          changeRoute('/');
        }
      } catch ({ code }) {
        if (code === "auth/user-not-found" || code === "auth/wrong-password") {
          notification(auth_labels.login_error_credentials, { status: 'danger' });
        } else {
          notification(auth_labels.login_error_unknown, { status: 'danger' });
        }
        console.warn(code);
      }
    }
  };
  render() {
    return (
      <div className='uk-section uk-padding-remove-vertical uk-animation-slide-top-small'>
        <div className='uk-container uk-container-xsmall uk-background-default uk-padding'>
          <h2 className='uk-text-center'>Login</h2>
          <form className='uk-form-horizontal' onSubmit={this.submitLoginForm}>
            <LabeledControl id='email_input' label='Email' required={true} className='uk-margin-bottom'>
              <input className='uk-input' type='email' ref={this.email_input_ref} />
            </LabeledControl>
            <LabeledControl id='password_input' label='Password' required={true} className='uk-margin-bottom'>
              <input className='uk-input' type='password' ref={this.password_input_ref} />
            </LabeledControl>
            <LabeledControl id='rem_device_input' label={auth_labels.rem_device} className='uk-margin-bottom'>
              <input className='uk-checkbox' type='checkbox' ref={this.rem_device_input_ref} />
            </LabeledControl>
            <div className='uk-text-center'>
              <input type='submit' value={auth_labels.login_button} className='uk-button uk-button-default' />
            </div>
          </form>
          <hr />
          <div className='uk-text-center uk-margin-top'>
            <GoogleLogin pathname={this.props.location.pathname} context={this.props.context} />
          </div>
          <div className='uk-text-center uk-margin-top'>
            <AppleLogin pathname={this.props.location.pathname} context={this.props.context} />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
