
export function dateFromTimestamp(ts) {
    const seconds = ts._seconds || ts.seconds;
    return new Date(seconds * 1000);
}

export function validateDataMapping({ dataMapping, objectFields, workingData }) {
    return dataMapping
        .filter(({ key, required }) => {
            const { type } = objectFields[key];
            const fieldRequired = objectFields[key].required;
            if(fieldRequired!==undefined) required = fieldRequired;
            const dataRow = workingData[key];
            let value;
            if (type === 'reference') {
                value = dataRow?.id;
            } else if (type === 'map') {
                const fieldsMap = objectFields[key].fields.reduce((acc, field) => Object.assign({ [field.key]: field }, acc), {});
                const validation = dataRow && Object.entries(dataRow).reduce((acc, [rkey, rval]) => {
                    return acc || Object.entries(fieldsMap).reduce((acc2, [fkey, fval]) => {
                        const subvalue = rval[fkey] || false;
                        if (fval.type === 'boolean') {
                            return acc2 || (!!fval.required && !subvalue);
                        } else if (fval.type === 'reference') {
                            return acc2 || (!!fval.required && !subvalue);
                        } else if (fval.type === 'delete') {
                            return acc2;
                        } else {
                            return acc2 || (!!fval.required && !subvalue);
                        }
                    }, false);
                }, false);
                //validate if data exists or is required
                if (validation && (!required && Object.keys(dataRow).length === 0)) {
                    return false;
                } else if (required) {
                    return !!validation;
                }
                return false;
            } else if (type === 'array') {
                const fieldsMap = objectFields[key].fields.reduce((acc, field) => Object.assign({ [field.key]: field }, acc), {});
                const validation = dataRow?.reduce((acc, rval, ridx) => {
                    return acc || Object.entries(fieldsMap).reduce((acc2, [fkey, fval]) => {
                        const subvalue = rval[fkey] || false;
                        if (fval.type === 'boolean') {
                            return acc2 || (!!fval.required && !subvalue);
                        } else if (fval.type === 'reference') {
                            return acc2 || (!!fval.required && !subvalue);
                        } else if (fval.type === 'delete' || fval.type === 'config') {
                            return acc2;
                        } else {
                            return acc2 || (!!fval.required && !subvalue);
                        }
                    }, false);
                }, false);
                //validate if data exists or is required
                if (validation && (!required && dataRow.length === 0)) {
                    return false;
                } else if (required) {
                    return !!validation;
                }
                return false;
            } else if (type === 'quill') {
                const quillContents = dataRow;
                value = !!quillContents || false;
            } else {
                value = dataRow;
            }
            return required && !value;
        })
        .map(({ key }) => objectFields[key].label);
}

export function processDataMapping({ db, dataMapping, objectFields, workingData, nestedForm }) {
    return dataMapping
        .reduceRight((acc, { key }) => {
            const { type, references, fields } = objectFields[key];
            const dataValue = workingData[key];
            if (type === 'reference') {
                if (dataValue) {
                    if (nestedForm) {
                        return Object.assign({ [key]: dataValue }, acc);
                    } else {
                        return Object.assign({ [key]: db.doc(`/${references}/${dataValue.id}`) }, acc);
                    }
                } else {
                    return Object.assign({ [key]: null }, acc);
                }
            } else if (type === 'money' || type === 'number') {
                if (dataValue) {
                    return Object.assign({ [key]: Number(dataValue) }, acc);
                } else {
                    return Object.assign({ [key]: null }, acc);
                }
            } else if (type === 'map') {
                if (dataValue) {
                    const fieldsMap = fields.reduce((acc, field) => Object.assign({ [field.key]: field }, acc), {});
                    const mapValues = Object.entries(dataValue)
                        .reduce((acc1, [rkey, rref]) => {
                            return Object.assign({
                                [rkey]: Object.entries(rref)
                                    .filter(([fkey, _]) => fkey!=='undefined' && fieldsMap[fkey])
                                    .reduce((acc2, [fkey, fref]) => {
                                        if (fieldsMap[fkey].type === 'boolean') {
                                            return Object.assign({ [fkey]: !!dataValue[rkey][fkey] }, acc2);
                                        } else if (fieldsMap[fkey].type === 'reference') {
                                            const field_references = fieldsMap[fkey].references;
                                            if(dataValue[rkey][fkey]) {
                                                if (nestedForm) {
                                                    return Object.assign({ [fkey]: dataValue[rkey][fkey] }, acc2);
                                                } else {
                                                    return Object.assign({ [fkey]: db.doc(`/${field_references}/${dataValue[rkey][fkey].id}`) }, acc2);
                                                }
                                            } else {
                                                return Object.assign({ [fkey]: null }, acc2);
                                            }
                                        } else {
                                            if(dataValue[rkey][fkey]) {
                                                return Object.assign({ [fkey]: dataValue[rkey][fkey] }, acc2);
                                            } else {
                                                return Object.assign({ [fkey]: null }, acc2);
                                            }
                                        }
                                    }, {})
                            }, acc1);
                        }, {});
                    return Object.assign({ [key]: mapValues }, acc);
                } else {
                    return Object.assign({ [key]: null }, acc);
                }
            } else if (type === 'array') {
                if (dataValue) {
                    const fieldsMap = fields.reduce((acc, field) => Object.assign({ [field.key]: field }, acc), {});
                    const arrayValues = dataValue.map((rref, ridx) => {
                        const resolvedRefs = Object.entries(rref)
                            .filter(([fkey, _]) => fkey!=='undefined' && fieldsMap[fkey])
                            .reduce((acc2, [fkey, fref]) => {
                                if (fieldsMap[fkey].type === 'boolean') {
                                    return Object.assign({ [fkey]: !!dataValue[ridx][fkey] }, acc2);
                                } else if (fieldsMap[fkey].type === 'reference') {
                                    const field_references = fieldsMap[fkey].references;
                                    if (nestedForm) {
                                        return Object.assign({ [fkey]: dataValue[ridx][fkey] }, acc2);
                                    } else {
                                        return Object.assign({ [fkey]: db.doc(`/${field_references}/${dataValue[ridx][fkey].id}`) }, acc2);
                                    }
                                } else {
                                    return Object.assign({ [fkey]: dataValue[ridx][fkey] }, acc2);
                                }
                            }, {});
                        return resolvedRefs;
                    });
                    return Object.assign({ [key]: arrayValues }, acc);
                } else {
                    return Object.assign({ [key]: null }, acc);
                }
            } else if (type === 'boolean') {
                if (dataValue) {
                    return Object.assign({ [key]: !!dataValue }, acc);
                } else {
                    return Object.assign({ [key]: false }, acc);
                }
            } else if (type === 'quill' || type !== undefined) {
                if (dataValue) {
                    return Object.assign({ [key]: dataValue }, acc);
                } else {
                    return Object.assign({ [key]: null }, acc);
                }
            } else {
                return acc;
            }
        }, {});
}