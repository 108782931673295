import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { firebaseStorage } from '../../firebase';
import algoliaLogoSvg from '../../resources/assets/logos/search-by-algolia-light-background.svg';

const searchClient = algoliasearch('DOUKI5UROU', '847a94a969126bdf98a769751feba2ce');
const campaignIndex = searchClient.initIndex('campaign');

function decodeSearchString(searchStr) {
    if (searchStr.indexOf('?') === -1 || searchStr.indexOf('=') === -1) return null;
    return searchStr.slice(1).split('&')
        .map(q => q.split('='))
        .reduce((acc, [k, v]) => ({ ...acc, [k]: decodeURIComponent(v) }), {});
}

const CampaignCard = (campaign) => {
    const { name, subtitle, gallery } = campaign;
    const [displayImage, setDisplayImage] = useState('');

    useEffect(() => {
        (async function () {
            if (gallery && gallery.length && gallery[0]?.file?.cloudPath) {
                try {
                    const url = await firebaseStorage.ref(gallery[0]?.file?.cloudPath).getDownloadURL();
                    setDisplayImage(url);
                } catch (err) {
                    console.error(err);
                }
            }
        })();
    }, [gallery]);

    return <>
        <div className='uk-card uk-card-default uk-card-hover uk-animation-fade'>
            <div className='uk-card-media-top'>
                <img data-src='' alt={name} uk-img={`dataSrc: ${displayImage}; width: 200px;`} />
            </div>
            <div className='uk-card-body'>
                <h3 className='uk-card-title'>{name}</h3>
                {subtitle}
            </div>
        </div>
    </>;
}

const FindCampaigns = ({ context, location }) => {
    const { changeRoute } = context;

    const pageSize = 8;
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultPage, setSearchResultPage] = useState(0);
    const [lastSearchResultPage, setLastSearchResultPage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const searchAlgoliaIndex = async (query, page = 0) => {
        if(query === null) {
            setSearchResults([]);
            setSearchResultPage(0);
            setLastSearchResultPage(undefined);
            return;
        }
        setLoading(true);
        const searchResult = await campaignIndex.search(query, {
            hitsPerPage: pageSize,
            page: page,
        });
        const { hits, nbPages } = searchResult;
        setSearchResultPage(page);
        setLastSearchResultPage(nbPages - 1); //off-by-one
        const updatedSearchResults = [...searchResults];
        updatedSearchResults[page] = hits;
        setSearchResults(updatedSearchResults);
        setLoading(false);
    };

    useEffect(() => {
        (async function () {    
            if (location.search) {
                const searchParams = decodeSearchString(location.search);
                setSearchQuery(searchParams.query);
                searchAlgoliaIndex(searchParams.query);
            } else {
                searchAlgoliaIndex(null);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const performSearch = async () => {
        if (!loading) {
            await searchAlgoliaIndex(searchQuery, searchResultPage);
            if(searchQuery) {
                changeRoute(`/campaign?query=${encodeURIComponent(searchQuery)}`);
            } else {
                changeRoute(`/campaign`);
            }
        }
    };
    const performSearchOSL = async (e) => {
        e.preventDefault();
        await performSearch();
    };
    const updateSearchQueryOCL = (e) => {
        setSearchQuery(e.target.value);
    };
    const loadNextPageOCL = async (e) => {
        e.preventDefault();
        if (lastSearchResultPage !== undefined && searchResultPage < lastSearchResultPage) {
            searchAlgoliaIndex(searchQuery, searchResultPage + 1);
        }
    }

    return <>
        <div className='uk-container uk-container-small uk-animation-slide-top-small'>
            <div className='uk-card uk-card-body uk-card-default uk-margin-bottom'>
                <form className='uk-search uk-search-large uk-width-1-1' onSubmit={performSearchOSL}>
                    <span uk-search-icon='true' />
                    <input className='uk-search-input' type='search' placeholder='Search...'
                        onChange={updateSearchQueryOCL} value={searchQuery} />
                </form>
                <div className='uk-flex uk-flex-right'>
                    <img src={algoliaLogoSvg} alt='Search by Algolia' className='uk-animation-fade' />
                </div>
            </div>
            <div className='uk-card uk-card-default uk-card-body uk-card-small uk-width-1-1 uk-width-large@s'>
                <ul uk-accordion='true'>
                    <li>
                        <button className='uk-button uk-button-link uk-accordion-title'>
                            Search Options
                        </button>
                        <div className='uk-accordion-content'>
                            <form className='uk-form-horizontal' onSubmit={()=>{}}>
                                <div className='uk-margin'>
                                    <label className='uk-form-label' htmlFor='cfa-search-category'>
                                        Filter By Category
                                    </label>
                                    <div className='uk-form-controls'>
                                        <select className='uk-select' id='cfa-search-category'>
                                            <option value={''}>All (default)</option>
                                            <option value={'Test Category'}>Test Category</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='uk-margin'>
                                    <label className='uk-form-label' htmlFor='cfa-search-sort'>
                                        Sort By
                                    </label>
                                    <div className='uk-form-controls'>
                                        <select className='uk-select' id='cfa-search-sort'>
                                            <option value={''}>Best (default)</option>
                                            <option value={'date'}>Close Date</option>
                                            <option value={'rating'}>User Rating</option>
                                            <option value={'goal'}>Funding Goal</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                            <div className='uk-flex uk-flex-row-reverse'>
                                <span className='uk-badge uk-padding-small'
                                    uk-tooltip='This feature is under development.'>
                                    DEV
                                </span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div className='uk-container uk-container-large'>
            {searchResults.length > 0 && <>
                <div className='uk-margin-large-top uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@l' uk-grid='true'>
                    {searchResults.flatMap(campaignList => campaignList.map(campaign =>
                        <Link key={campaign.objectID} to={`/campaign/${campaign.objectID}`} className='uk-link-reset'>
                            <CampaignCard {...campaign} />
                        </Link>
                    ))}
                </div>
            </>}
            {lastSearchResultPage !== undefined &&
                <div className='uk-flex uk-flex-center uk-margin-large-top'>
                    {searchResultPage < lastSearchResultPage ? <>
                        <button className='uk-button uk-button-default' onClick={loadNextPageOCL}>
                            See More
                        </button>
                    </> : <>
                            <span className='uk-text-meta'>
                                No more Campaigns found.
                            </span>
                        </>}
                </div>
            }
        </div>
    </>;
}

export default FindCampaigns;