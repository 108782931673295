import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/analytics';

export const firebaseConfig = {
    apiKey: "AIzaSyBvEhsaOdj2kYgjJVNcxMzvpKhnp2BbGk0",
    authDomain: "crowdfunding-webapp-dev.firebaseapp.com",
    databaseURL: "https://crowdfunding-webapp-dev.firebaseio.com",
    projectId: "crowdfunding-webapp-dev",
    storageBucket: "crowdfunding-webapp-dev.appspot.com",
    messagingSenderId: "321600018385",
    appId: "1:321600018385:web:79f268c9b30bab7b1d72f0",
    measurementId: "G-6Q4SV30610",
    recaptchaSiteKey: "6Le_5LEZAAAAAAitgG9pp0IbjlnGZCA8XYxLnDfE"
};
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firestoreDb = firebaseApp.firestore();
export const firebasePerformance = firebaseApp.performance();
export const firebaseAnalytics = firebaseApp.analytics();
export const firebaseStorage = firebase.storage();
export const firebaseAuth = firebase.auth;
export const useGoogleAuthProvider = () => new firebase.auth.GoogleAuthProvider();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const timestamp = firebase.firestore.Timestamp;

export function dateFromTimestamp(ts) {
    const seconds = ts._seconds || ts.seconds;
    return new Date(seconds * 1000);
}