import { dateFromTimestamp } from '../components/core/object/ObjectDataMap';
// import { campaignDefinition } from './objects/campaignDefinition';

export function numberToMoney(num) {
  if (typeof num === 'number') {
    return (num).toFixed(2);
  } else {
    return numberToMoney(parseFloat(num))
  }
}

export function renderTimestamp(ts) {
  if (!ts) return ts;
  return dateFromTimestamp(ts).toLocaleString();
}

//from https://stackoverflow.com/a/14919494
export function humanFileSize(bytes, si=true, dp=1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si 
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + '' + units[u];
}

export const pageDefinitions = {
  // '/campaign': {
  //   isAuth: false,
  //   pageType: 'dynamic',
  //   viewType: 'breadcrumb',
  //   objectConfiguration: campaignDefinition
  // }
};