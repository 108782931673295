import React, { lazy } from 'react';

import { withSecureContext, withContext } from '../appState';
import { pageDefinitions } from './projectConfiguration';

const StackedPageView = lazy(() => import('../components/core/StackedPageView'));
const BreadcrumbPageView = lazy(() => import('../components/core/BreadcrumbPageView'));

export const dynamicPageRoute = (page, pageOptions={}) => {
  const { isAuth, pageType, viewType='breadcrumb', objectConfiguration, customComponent } = pageDefinitions[page];
  const { baseLinkMappings={} } = pageOptions;
  const wrapper = isAuth ? withSecureContext : withContext;
  if ('dynamic' === pageType) {
    const pageConfig = objectConfiguration;
    return wrapper((props) => {
      const firestoreCollectionService = props.context.services[pageConfig.collectionName];
      const customConfig = Object.assign({ firestoreCollectionService }, pageConfig);
      if(viewType==='breadcrumb') {
        return <BreadcrumbPageView configuration={customConfig} baseLinkMappings={baseLinkMappings} {...props} />;
      } else {
        return <StackedPageView configuration={customConfig} {...props} />
      }
    });
  } else if (customComponent) {
    return wrapper(customComponent);
  } else { //static page
    //todo
    return null;
  }
};