import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { notification } from 'uikit';

import { DividedTable, Pagination } from '../../../Util';
import { withContext } from '../../../../appState';
import { dateFromTimestamp } from '../ObjectDataMap';

function buildSearchString(page, viewType) {
    let search = '';
    if (page || viewType) search += '?';
    if (page) search += `page=${page}`;
    if (page && viewType) search += '&';
    if (viewType) search += `viewType=${viewType}`;
    return search;
}

class TableObjectView extends PureComponent {
    state = {
        numObjectsPerPage: 8,
        loading: false,
        tableData: []
    };
    async componentDidMount() {
        await this.fetchPageData(true);
    }
    updatePageURL = (newPageNum, newViewType) => {
        const { changeRoute } = this.props.context;
        const pageNumber = newPageNum || this.getCurrentPage();
        const viewType = newViewType || this.getViewType();
        changeRoute({
            search: buildSearchString(pageNumber, viewType)
        });
    };
    getCurrentPage = () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        return Number(searchParams.get('page')) || 1;
    };
    getViewType = () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        return searchParams.get('viewType') || this.props.viewType || 'default';
    };
    fetchPageData = async (useCache = true) => {
        const { configuration, context } = this.props;
        const { firestoreCollectionService, objectFields } = configuration;
        const { numObjectsPerPage } = this.state;
        const pageNumber = this.getCurrentPage();
        const viewType = this.getViewType();
        this.setState(() => ({ loading: true }));
        try {
            const tableData = await firestoreCollectionService.getObjectsPage(pageNumber, numObjectsPerPage, useCache, viewType);
            for (const tr of tableData) {
                //get reference object keys
                const refObjects = Object.keys(tr)
                    .filter((key) => objectFields[key])
                    .filter((key) => objectFields[key].type === 'reference' && tr[key] && tr[key].id);
                //fetch reference data
                const resolvedRefs = await Promise.all(refObjects.map((refKey) => {
                    const objectId = tr[refKey].id;
                    const referencedService = context.services[objectFields[refKey].references];
                    return referencedService.getObject(objectId);
                }));
                for (let i = 0; i < refObjects.length; i++) {
                    tr[refObjects[i]] = resolvedRefs[i];
                }
            }
            this.setState(() => ({ loading: false, tableData: tableData }));
        } catch (err) {
            console.error(err);
            this.handleGetObjectsError(err);
            this.setState(() => ({ loading: false }));
        }
    };
    changePage = async (page) => {
        const { firestoreCollectionService } = this.props.configuration;
        const { numObjectsPerPage } = this.state;
        const viewType = this.getViewType();
        try {
            await firestoreCollectionService.getObjectsPage(page, numObjectsPerPage, true, viewType);
            this.updatePageURL(page);
        } catch (err) {
            this.handleGetObjectsError(err);
            console.error(err);
        }
    };
    handleGetObjectsError = ({ name, code, page, details }) => {
        if (code === "invalid_page") {
            notification.closeAll();
            notification(`Page ${page} is not a valid page.`);
        }
        if (code === "no_data" && page > 1) {
            notification.closeAll();
            notification(`Could not find any records on page ${page}.`);
        }
    };
    refreshTableOCL = async (e) => {
        await this.fetchPageData(false);
    };
    render() {
        const { tableData, numObjectsPerPage } = this.state;
        const { animate, location, className = '', pathRoot, configuration } = this.props;
        const { objectName, objectFields, tableDataMapping } = configuration;
        const pageNumber = this.getCurrentPage();
        const pageView = this.getViewType();
        const maxPage = Math.floor(tableData.length / numObjectsPerPage) + pageNumber;
        const allowed = typeof configuration.allowed === 'function' ? configuration.allowed({ view: 'table', data: null, user: this.props.context.auth.user }) : configuration.allowed;
        const views = typeof configuration.views === 'function' ? configuration.views({ view: 'table', data: null, user: this.props.context.auth.user }) : configuration.views;
        return (
            <div className={`uk-container ${!!animate ? "uk-animation-slide-bottom-small" : ""} ${className}`}>
                {views && views.length>1 && 
                    <div className='uk-grid-small uk-child-width-1-2@s' uk-grid='true'>
                        <div className='uk-flex uk-flex-center uk-flex-left@s uk-margin-bottom'>
                            <ul className='uk-tab-right' uk-tab='toggle: > a; media: @s;'>
                                {views.map(({ label, key }) => (
                                    <li className={pageView === key ? 'uk-active' : ''} key={`tableView_viewToggle_${key}`}>
                                        <Link to={`${window.location.pathname}${buildSearchString(undefined, key)}`}>
                                            {label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                }
                <div className="uk-flex uk-flex-between uk-margin-bottom">
                    {allowed?.create && (
                        <Link to={`/${pathRoot}/new${location.search}`} className="uk-button uk-button-secondary uk-margin-right" title={`New ${objectName}`}>New {objectName}</Link>)}
                    <button onClick={this.refreshTableOCL} className="uk-button uk-button-default uk-margin-right" title="Refresh">Refresh</button>
                </div>
                <div style={{ overflowX: "auto" }}>
                    <DividedTable columnMappings={tableDataMapping.reduceRight((acc, { key, viewLink, truncate }) => {
                        const { label, type, listEntries } = objectFields[key];
                        //if we have a reference, we have to resolve a name for it first
                        const getColumnValue = (data) => {
                            let columnValue = data[key];
                            if (type === 'reference') {
                                columnValue = data[key]?.name || '';
                            } else if (type === 'timestamp') {
                                columnValue = dateFromTimestamp(data[key]).toLocaleString();
                            } else if (type === 'money') {
                                if (typeof data[key] === 'number') {
                                    columnValue = '$' + data[key].toFixed(2);
                                } else {
                                    columnValue = data[key];
                                }
                            } else if (type === 'dropdown' && listEntries) {
                                const valueLookup = listEntries.reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});
                                columnValue = valueLookup[data[key]] || data[key];
                            }
                            if (columnValue && truncate) {
                                const slicedValue = columnValue.toString().slice(0, truncate);
                                //if truncation was productive, update columnValue
                                if (columnValue.toString().length > slicedValue.length) {
                                    columnValue = `${slicedValue}...`;
                                }
                            }
                            return columnValue;
                        }
                        //prepare value for rendering
                        let elementView;
                        if (viewLink) {
                            elementView = {
                                render: (data) => (
                                    <Link to={`/${pathRoot}/${data.id}${location.search}`} className="uk-link-reset" title={`View ${objectName}`}>
                                        {getColumnValue(data)}
                                    </Link>
                                ), className: 'uk-table-link'
                            };
                        } else {
                            elementView = getColumnValue;
                        }
                        //map each label to the appropriate rendering, and add other elements from accumulator
                        return Object.assign({ [label]: elementView }, acc);
                    }, {})} id="TableObjectView" loading={this.state.loading} data={tableData} />
                </div>
                <Pagination pageNumber={pageNumber} changePage={this.changePage} lastPage={maxPage} />
            </div>
        )
    }
}
export default withContext(TableObjectView);