// import firebase from 'firebase/app';
import React, { PureComponent, Fragment } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { notification } from 'uikit';
import { LabeledControl } from '../components/Util';
import { GoogleLogin, AppleLogin } from '../components/LoginPage';
// import { auth_labels } from '../resources/labels';

const formFields = [
    { key: 'firstName', label: 'First Name', required: true },
    { key: 'lastName', label: 'Last Name', required: true, hrBelow: true },
    { key: 'email', label: 'Email', type: 'email', required: true },
    { key: 'password', label: 'Password', type: 'password', minLength: 6, required: true },
    { key: 'confirmPassword', label: 'Confirm Password', type: 'password', minLength: 6, required: true },
];

class SignUpForm extends PureComponent {
    formRefs = formFields.reduce((acc, { key }) => ({ [key]: React.createRef(), ...acc }), {});
    recaptchaRef = React.createRef();
    formOSL = async (e) => {
        e.preventDefault();
        const { context } = this.props;
        const { changeRoute, userAdminService } = context;
        const recaptchaValue = this.recaptchaRef.current.getValue();
        const fieldValues = Object.entries(this.formRefs).reduce((acc, [key, ref]) => ({ [key]: ref.current.value, ...acc }), {});
        if(!recaptchaValue) {
            notification('Please complete the ReCAPTCHA challenge to continue.', { status: 'danger' });
            return;
        }
        if(fieldValues.password !== fieldValues.confirmPassword) {
            notification('Passwords do not match.', { status: 'danger' });
            return;
        }
        
        const cleanedUserObject = {
            email: fieldValues.email,
            password: fieldValues.password,
            displayName: `${fieldValues.firstName} ${fieldValues.lastName}`
        };
        notification('Creating user account...');
        const res = await userAdminService.createUserPublic(cleanedUserObject, recaptchaValue);
        //we got an error
        if(res.code && res.message) {
            this.formError(res.message);
        } else {
            if(res.uid) {
                notification.closeAll();
                notification('Success! Login using your new account.', { status: 'success' });
                changeRoute('/login');
            } else {
                this.formError('Unknown error occured. Please try again later.');
            }
        }
    };
    formError = (message) => {
        notification.closeAll();
        notification(message, { status: 'danger' });
        //reset recaptcha after failed challenges
        this.recaptchaRef.current.reset();
    };
    render() {
        const { recaptchaSiteKey } = this.props.context.auth;
        const { darkTheme } = this.props.context.system;
        return (
            <form className='uk-form-horizontal uk-margin-top' onSubmit={this.formOSL} autoComplete='off'>
                {formFields.map(({ key, label, minLength, type = 'text', required = false, hrBelow = false }) => (
                    <Fragment key={key}>
                        <LabeledControl id={`user_${key}_input`} label={label} className='uk-margin-bottom' required={required} key={key} >
                            <input className='uk-input' type={type} ref={this.formRefs[key]} minLength={minLength} data-lpignore='true' />
                        </LabeledControl>
                    </Fragment>
                ))}
                <div className='uk-flex uk-flex-column uk-flex-middle'>
                    <ReCAPTCHA sitekey={recaptchaSiteKey} theme={darkTheme ? 'dark' : 'light'} ref={this.recaptchaRef}/>
                    <input type='submit' value={'Sign Up'} className='uk-margin-top uk-button uk-button-default'/>
                </div>
            </form>
        );
    }
}

class SignUpPage extends PureComponent {
    render() {
        const context = this.props.context;
        return (
            <div className='uk-section uk-padding-remove-vertical uk-animation-slide-top-small'>
                <div className='uk-container uk-container-xsmall uk-background-default uk-padding'>
                    <h2 className='uk-text-center'>Sign Up</h2>
                    <SignUpForm context={context} />
                    <hr/>
                    <div className='uk-text-center uk-margin-top'>
                        <GoogleLogin location={this.props.location} context={this.props.context} buttonText='Sign up with Google' />
                    </div>
                    <div className='uk-text-center uk-margin-top'>
                        <AppleLogin location={this.props.location} context={this.props.context} buttonText='Sign up with Apple' />
                    </div>
                </div>
            </div>
        );
    }
}

export default SignUpPage;