import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

export function WorkflowStateMessage({ title, message }) {
    return <>
        <div className='uk-container-small uk-text-center uk-width-1-1'>
            <h2>{title}</h2>
            <p>{message}</p>
        </div>
    </>;
}

export const NoInitialStateError = () => <WorkflowStateMessage title='Workflow Error' message='No initial state was provided. Please configure an initial state and try again.' />;
export const RequestedInvalidStateError = () => <WorkflowStateMessage title='Workflow Error' message='Attempted to render an invalid workflow state. Please go back and try again.' />;

const noCodeWarningMessage = 'WorkflowStateMachine Warning\nAll child states must have a non-null and unique "code" prop.';

function validateStateTransition(fromState, toState, stateTransitionMap) {
    return fromState in stateTransitionMap &&
        toState in stateTransitionMap[fromState] &&
        stateTransitionMap[fromState][toState];
}

function calculateWorkflowState(location, pathRoot) {
    let state = location.pathname.slice(location.pathname.lastIndexOf(pathRoot) + pathRoot.length);
    if (state.startsWith('/')) {
        state = state.slice(1);
    }
    return state;
}

export default function WorkflowStateMachine({
    children, location, pathRoot, context,
    stateTransitionMap = {}, initialState = 'initial',
    mixins = {}, validateTransitions = false,
}) {
    const [workflowState, setWorkflowState] = useState(calculateWorkflowState(location, pathRoot));
    const [childStates, setChildStates] = useState({});
    useEffect(() => {
        if(workflowState==='') setWorkflowState(initialState);
        const changeWorkflowStateOCL = (newState) => () => {
            let transitionApproved = true;
            if (validateTransitions) {
                transitionApproved = validateStateTransition(workflowState, newState, stateTransitionMap);
            }
            if(transitionApproved) {
                setWorkflowState(newState);
                context.changeRoute(`/${pathRoot}/${newState}`);
            }
        };
        //setup states and transitions
        const _cs = React.Children.toArray(children)
            .map((e, i) => {
                //determine workflow state code
                let code = e.props.code;
                if (!code) {
                    console.warn(noCodeWarningMessage);
                    code = i;
                }
                return React.cloneElement(e, {
                    stateMachine: {
                        changeState: changeWorkflowStateOCL,
                        ...mixins,
                    }
                });
            })
            .reduce((acc, el) => ({ ...acc, [el.props.code]: el }), {});
        setChildStates(_cs);
    }, [children, mixins, context, pathRoot, stateTransitionMap, validateTransitions, initialState, workflowState]);
    console.log(workflowState);
    if(!initialState) {
        return <NoInitialStateError />;
    }
    if(!childStates.hasOwnProperty(workflowState)) {
        return <RequestedInvalidStateError />;
    }
    return <>
        <Switch location={location}>
            {Object.keys(childStates).map(stateCode => (
                <Route path={`/${pathRoot}/${stateCode}`} key={stateCode}>
                    {childStates[stateCode]}
                </Route>
            ))}
            <Route exact path={`/${pathRoot}`}>
                {childStates[initialState]}
            </Route>
        </Switch>
    </>
}