import { observer } from 'mobx-react-lite';
import React, { PureComponent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { offcanvas } from 'uikit';
import { withContext } from '../appState';
import { global_labels, nav_links } from '../resources/labels';

const NavbarOffCanvas = observer(({ context, id, pathname }) => {
  const { isLoggedIn, isAdmin, user } = context.auth;
  useEffect(() => {
    offcanvas(`#${id}`).hide();
  }, [pathname, id, context.auth]);
  return (
    <div id={id} uk-offcanvas="overlay: true;">
      <div className="uk-offcanvas-bar">
        <button className="uk-offcanvas-close" type="button" title="Close Menu" uk-close="true" />
        <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical">
          {nav_links
            .filter(({ adminOnly, signedIn }) => {
              let show = true;
              if (signedIn === true) show &= isLoggedIn;
              if (signedIn === false) show &= !isLoggedIn;
              if (adminOnly === true) show &= isAdmin;
              return show;
            })
            .map(({ label, link }, link_i) => (
              <li key={`navlink_${label}`}><Link to={link}>{label}</Link></li>
            ))}
        </ul>
        <hr />
        {isLoggedIn ? <>
          <ul className='uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical'>
            <li><Link to={`/user`}>{user?.displayName || 'User'}</Link></li>
            <li><Link to={`/logout`}>Logout</Link></li>
          </ul>
        </> : <>
            <ul className='uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical'>
              <li><Link to={`/login`}>Login</Link></li>
              <li><Link to={`/signUp`}>Sign Up</Link></li>
            </ul>
          </>}
      </div>
    </div>
  );
});

class Navbar extends PureComponent {
  showOffcanvas = () => {
    offcanvas('#navbar-offcanvas').show();
  }
  render() {
    const { context, location } = this.props;
    return <>
      <nav className="uk-navbar-container uk-navbar-transparent" uk-navbar="true">
        <div className="uk-navbar-left">
          <button className="uk-navbar-toggle" uk-navbar-toggle-icon="true"
            onClick={this.showOffcanvas} title="Show Menu" />
        </div>
        <div className="uk-navbar-center">
          <div className="uk-navbar-item uk-logo">
            <span className='uk-width-4-5 uk-width-1-1@s uk-text-truncate'>
              <Link to="/" className='uk-link-reset'>
                {global_labels.title}
              </Link>
            </span>
          </div>
        </div>
        <div className="uk-navbar-right">
          <Link className="uk-navbar-item uk-link-reset" to="/campaign">
            <span uk-icon='search' />
          </Link>
        </div>
      </nav>
      <NavbarOffCanvas id="navbar-offcanvas" context={context} pathname={location.pathname} />
    </>;
  }
}

export default withContext(Navbar);
