import React, { PureComponent } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { SearchBox, InstantSearch, connectHits } from 'react-instantsearch-dom';

import { DividedTable } from '../../../Util';

import { ModalError } from './Modal';

import './SearchObjectModal.scss';

const searchClient = algoliasearch('DOUKI5UROU', '847a94a969126bdf98a769751feba2ce');

const CustomHits = ({ hits, columnMappings }) => {
    return (
        <div className='uk-overflow-auto'>
            <DividedTable id='test' data={hits} columnMappings={columnMappings} />
        </div>
    );
}
const Hits = connectHits(CustomHits);

class SearchObjectModal extends PureComponent {
    modal_ref = React.createRef();
    search_query_ref = React.createRef();
    tableColumnMappings = null;
    state = {
        searchResults: null,
        selectedObject: null
    };
    constructor(props) {
        super(props);
        //build table column mappings from modal description
        this.tableColumnMappings = {};
        this.tableColumnMappings['Action'] = (data) => (
            <button className="uk-button uk-button-secondary"
                onClick={this.pickSearchObject(data)} title={`Pick ${data.name}`}>Pick</button>
        );
        this.tableColumnMappings['Name'] = (data) => data['name'] || data['description'];
        this.tableColumnMappings['Code'] = (data) => data['code'];
    }
    startSearch = async () => {
        const { context } = this.props;
        const { modal } = context;
        const modalElement = modal(this.modal_ref.current);
        this.setState(() => ({ searchResults: null, selectedObject: null }), () => {
            //this.search_query_ref.current.value = "";
            modalElement.show();
        });
        return new Promise((resolve, reject) => {
            this.modal_ref.current.addEventListener('hidden', () => {
                const selection = this.state.selectedObject;
                if (selection) {
                    resolve(selection);
                } else {
                    reject(new ModalError('invalid_item', 'Expected to receive result from modal but received nothing'));
                }
            });
        });
    };
    getSearchResults = async (e) => {
        e.preventDefault();
        const { context, referencedCollection } = this.props;
        const searchQuery = e.target[0].value//this.search_query_ref.current.value;
        try {
            const products = await context.services[referencedCollection].search(searchQuery, { byName: true });
            this.setState(() => ({ searchResults: products }));
        } catch (err) {
            if (err.code === "no_data") {
                context.notification("No matching objects found.");
            } else {
                context.notification(err, { status: 'danger' });
            }
        }
    };
    pickSearchObject = (data) => async (e) => {
        e.preventDefault();
        if (!data || !data.objectID) return;
        const { context, referencedCollection } = this.props;
        const modalElement = context.modal(this.modal_ref.current);
        const object = await context.services[referencedCollection].getObject(data.objectID);
        this.setState(() => ({ selectedObject: object, searchResults: null }), () => {
            modalElement.hide();
        });
    };
    render() {
        const { parentKey, dataKey, configuration, referencedCollection } = this.props;
        const { objectName, objectFields } = configuration;
        const field = parentKey ? objectFields[parentKey].fields[dataKey] : objectFields[dataKey];
        const searchObjectName = field.label;
        return (
            <div id={`${objectName}_search${searchObjectName}Modal`} uk-modal="stack: true; bg-close: true;" ref={this.modal_ref}>
                <div className="uk-modal-dialog uk-modal-body">
                    <h4>Search for {searchObjectName}</h4>
                    <InstantSearch searchClient={searchClient} indexName={referencedCollection}>
                        <SearchBox searchAsYouType={true} showLoadingIndicator={false} />
                        <Hits columnMappings={this.tableColumnMappings} />
                    </InstantSearch>
                    <button className="uk-modal-close-default" type="button" uk-close="true" />
                </div>
            </div>
        );
    }
}

export default SearchObjectModal;