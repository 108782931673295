import React, { createContext } from 'react';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import { configure } from 'mobx';
import DefaultAuthenticationFallback from './components/LoginPage';
import RootStore from './stores/RootStore';

//register UIkit Icons globally so they can be used throughout the site
UIkit.use(Icons);

//disable proxies on mobx for IE11
configure({
    useProxies: 'ifavailable'
});

const rootStore = new RootStore();

//use App as the root for global state
export const ApplicationContext = createContext();
export const ApplicationContextProvider = (props) => (
    <ApplicationContext.Provider value={rootStore}>
        {props.children}
    </ApplicationContext.Provider>
);
export const withContext = (Component) => (props) => (
    <ApplicationContext.Consumer>
        {context => <Component context={context} {...props} />}
    </ApplicationContext.Consumer>
);

//HOC to switch between two components based on authentication state, as well as pass context
export const withSecureContext =
    (Component, Fallback = DefaultAuthenticationFallback) => withContext((props) => (
        props.context.auth.isLoggedIn ? <Component {...props} /> : <Fallback {...props} />
    ));