import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbSectionContainer } from '../../components/Util';
import { withContext } from '../../appState';
import EditableObjectView from '../../components/core/object/views/EditableObjectView';
import { campaignDefinition } from '../../configuration/objects/campaignDefinition';

import MyCampaigns from './MyCampaigns';
import NewCampaign from './NewCampaign';
import FindCampaigns from './FindCampaigns';
import { observer } from 'mobx-react-lite';

const CampaignHome = ({ context, location }) => {
    const { auth } = context;
    return <>
        <div className='uk-section'>
            {auth.user && <>
                <div className='uk-container uk-container-small uk-margin-bottom uk-animation-slide-top-small'>
                    <div className='uk-card uk-card-default uk-card-body'>
                        <h3 className='uk-card-title'>Welcome, {auth.user.displayName || 'User'}</h3>
                        <ul uk-accordion='true'>
                            <li className='uk-open'>
                                <button className='uk-button uk-button-link uk-accordion-title'>Quick Links</button>
                                <div className='uk-accordion-content'>
                                    <ul uk-nav='true'>
                                        <li><Link to='/campaign/new'>Create a new Campaign</Link></li>
                                        <li><Link to='/campaign/user'>Manage an existing Campaign</Link></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>}
            <FindCampaigns context={context} location={location} />
        </div>
    </>;
};

const CampaignPage = observer(({ location, match, context }) => {
    const { darkTheme } = context.system;
    const pathRoot = match.path.slice(1);
    const sectionThemeClass = darkTheme ? 'uk-section-secondary' : 'uk-section-default';
    const configuration = { ...campaignDefinition, firestoreCollectionService: context[campaignDefinition.collectionName] };
    return (
        <Switch location={location}>
            <Route exact path={`/${pathRoot}`}>
                <CampaignHome context={context} location={location} />
            </Route>
            <Route path={`/${pathRoot}/new**`}>
                <NewCampaign context={context} location={location} pathRoot={`${pathRoot}/new`} configuration={configuration} />
            </Route>
            <Route path={`/${pathRoot}/user**`}>
                <MyCampaigns context={context} location={location} pathRoot={pathRoot} configuration={configuration} />
            </Route>
            <Route path={`/${pathRoot}/:objectId/:pageMode?`}>
                {(nestedRouteParams) => {
                    const { objectId, pageMode } = nestedRouteParams.match.params;
                    let breadcrumbLabel = objectId;
                    const baseLinkMappings = { 'Campaigns': `/${pathRoot}` };
                    const parentLinkMappings = {};
                    if (pageMode === 'edit') {
                        breadcrumbLabel = 'Edit';
                        parentLinkMappings[objectId] = `/${pathRoot}/${objectId}`;
                    }
                    return (
                        <>
                            <BreadcrumbSectionContainer>
                                <Breadcrumb label={breadcrumbLabel} parentLinkMappings={{ ...baseLinkMappings, ...parentLinkMappings }} />
                            </BreadcrumbSectionContainer>
                            <div className={`uk-section ${sectionThemeClass} uk-animation-small-slide-top`}>
                                <EditableObjectView objectId={objectId} mode={pageMode} location={location}
                                    pathRoot={pathRoot} configuration={configuration} hideCloseButton={true} />
                            </div>
                        </>
                    );
                }}
            </Route>
        </Switch>
    );
});

export default withContext(CampaignPage);