import React from 'react';
import { Link } from 'react-router-dom';
import { LabeledControl } from '../../../components/Util';
import FirestoreObjectStateMachine from '../../core/stateMachine/FirestoreObjectStateMachine';
import FormikWorkflowState from '../../core/states/FormikWorkflowState';
import SimpleWorkflowState from '../../core/states/SimpleWorkflowState';
import { campaignCategoryList } from '../../../configuration/objects/campaignDefinition';

function ButtonPanel({ children }) {
    return (
        <div className='uk-flex uk-flex-center uk-margin-large-top'>
            {children}
        </div>
    );
}

function validateInitialState(values) {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.subtitle) {
        errors.subtitle = 'Required';
    }
    return errors;
}

function validateCategoryDetailsState(values) {
    const errors = {};
    if (!values.category) {
        errors.category = 'Required';
    }
    return errors;
}

export default function NewCampaignWorkflow({ location, pathRoot }) {
    const objectId = '999999-1';
    return <>
        <FirestoreObjectStateMachine location={location} pathRoot={pathRoot}
            initialState='initial' collectionName='campaign' objectId={objectId}
        >
            <SimpleWorkflowState code='initial'>
                {({ changeState }) => <>
                    <div>
                        <h2>Welcome!</h2>
                        <p>This is a test workflow.</p>
                        <ButtonPanel>
                            <button onClick={changeState('basicInfo')} className='uk-button uk-button-default'>
                                Next
                            </button>
                        </ButtonPanel>
                    </div>
                </>}
            </SimpleWorkflowState>

            <FormikWorkflowState code='basicInfo'
                initialValues={{ name: '', subtitle: '' }}
                validate={validateInitialState}
                nextState='categoryDetails'
            >
                {(formik, { changeState }) => <>
                    <div>
                        <h2>Basic Info</h2>
                        <form onSubmit={formik.handleSubmit}>
                            <LabeledControl id='name' label='Name'>
                                <input id='name' className='uk-input' type='text' {...formik.getFieldProps('name')} />
                            </LabeledControl>
                            {formik.touched.name && formik.errors.name ? (
                                <div className='uk-text-danger'>{formik.errors.name}</div>
                            ) : null}
                            <LabeledControl id='subtitle' label='Subtitle'>
                                <input id='subtitle' className='uk-input' type='text' {...formik.getFieldProps('subtitle')} />
                            </LabeledControl>
                            {formik.touched.subtitle && formik.errors.subtitle ? (
                                <div className='uk-text-danger'>{formik.errors.subtitle}</div>
                            ) : null}
                            <ButtonPanel>
                                <button onClick={changeState('initial')} className='uk-button uk-button-default'>
                                    Go Back
                                </button>
                                <button type='submit' className='uk-button uk-button-primary uk-margin-left'>
                                    Next
                                </button>
                            </ButtonPanel>
                        </form>
                    </div>
                </>}
            </FormikWorkflowState>

            <FormikWorkflowState code='categoryDetails'
                initialValues={{ category: '' }}
                validate={validateCategoryDetailsState}
                nextState='state3'
            >
                {formik => <>
                    <div>
                        <h2>Basic Info</h2>
                        <form onSubmit={formik.handleSubmit}>
                            <LabeledControl id='category' label='Category'>
                                <select id='category' name='category' className='uk-select' {...formik.getFieldProps('category')}>
                                    {campaignCategoryList.map(({ label, value }) => (
                                        <option value={value} key={value}>{label}</option>
                                    ))}
                                </select>
                            </LabeledControl>
                            {formik.touched.category && formik.errors.category ? (
                                <div className='uk-text-danger'>{formik.errors.category}</div>
                            ) : null}
                            <ButtonPanel>
                                <button type='submit' className='uk-button uk-button-primary'>
                                    Next
                                </button>
                            </ButtonPanel>
                        </form>
                    </div>
                </>}
            </FormikWorkflowState>

            <SimpleWorkflowState code='state3'>
                {({ changeState, latestObject }) => <>
                    <div>
                        <h2>Results</h2>
                        <p>Campaign Name: {latestObject.name}</p>
                        <p>Category: {latestObject.category}</p>
                        <ButtonPanel>
                            <button onClick={changeState('initial')} className='uk-button uk-button-default'>
                                Start Over
                            </button>
                            <button onClick={changeState('final')} className='uk-button uk-button-primary uk-margin-left'>
                                Submit
                            </button>
                        </ButtonPanel>
                    </div>
                </>}
            </SimpleWorkflowState>

            <SimpleWorkflowState code='final'>
                {({ changeState }) => <>
                    <div>
                        <h2>Done!</h2>
                        <p>Your campaign is ready for further editing.</p>
                        <ButtonPanel>
                            <button onClick={changeState('state3')} className='uk-button uk-button-default'>
                                Go Back
                            </button>
                            <Link to={`/campaign/${objectId}`} className='uk-button uk-button-secondary uk-margin-left'>
                                View Campaign
                            </Link>
                        </ButtonPanel>
                    </div>
                </>}
            </SimpleWorkflowState>
        </FirestoreObjectStateMachine>
    </>;
}