import React, { Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';

export const LabeledControl = ({ id, label, children, className,
  required = false, requiredMessage = 'Required Field' }) => (
    <div className={className}>
      <label className="uk-form-label" htmlFor={id}>
        {label}
        {required && <span uk-icon="icon:warning; ratio:0.75;"
          className="uk-margin-small-left uk-text-middle" uk-tooltip={requiredMessage} />}
      </label>
      <div className="uk-form-controls">
        {React.cloneElement(children, { id, required })}
      </div>
    </div>
  );

export const DividedTable = ({ id, data, columnMappings, className = '', animate = true, loading = false }) => (
  <table className={`uk-table uk-table-divider ${className}`} id={id}>
    <thead>
      <tr>
        {Object.keys(columnMappings).map((label, i) => (
          <th key={`${id}_header_${i}`}>{label}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {(!loading && data.length > 0) ? data.map((dataRow, rowNum) => (
        <tr key={`${id}_tablerow_${rowNum}`} className={animate ? 'uk-animation-fade' : ''}>
          {Object.values(columnMappings).map((columnMapping, i) => {
            if (typeof columnMapping === 'function') {
              return (
                <td key={`${id}_tablecell_${rowNum}_${i}`}>{columnMapping(dataRow, rowNum, i)}</td>
              );
            } else if (typeof columnMapping.render === 'function') {
              return (
                <td key={`${id}_tablecell_${rowNum}_${i}`}
                  className={columnMapping.className}>{columnMapping.render(dataRow, rowNum, i)}</td>
              );
            } else {
              return (
                <td key={`${id}_tablecell_${rowNum}_${i}`} />
              );
            }
          })}
        </tr>
      )) : (
          <tr>
            <td colSpan={Object.keys(columnMappings).length}>
              {loading ? "Loading..." : "No Results"}
            </td>
          </tr>
        )}
    </tbody>
  </table>
);

export const BreadcrumbSectionContainer = ({ children }) => (
  <div className='uk-section uk-section-xsmall uk-animation-fade'>
    <div className='uk-container'>
      {children}
    </div>
  </div>
);

export const Breadcrumb = ({ label, parentLinkMappings }) => (
  <ul className="uk-breadcrumb">
    {Object.entries(parentLinkMappings).map(([label, link], i) => (
      <li key={`breadcrumblink_${link}`}><Link to={link}>{label}</Link></li>
    ))}
    <li><span>{label}</span></li>
  </ul>
);

export const Pagination = ({ pageNumber, changePage, lastPage = Number.MAX_SAFE_INTEGER }) => (
  <ul className="uk-pagination uk-flex-center uk-margin-bottom">
    {pageNumber > 1 && (
      <li>
        <button onClick={() => { changePage(pageNumber - 1) }} className="uk-button uk-button-text" title={`Previous Page`}>
          <span uk-pagination-previous="true" />
        </button>
      </li>
    )}
    {Array(3).fill(null)
      .map((e, i) => (i - 1 + pageNumber))
      .filter((page) => page > 0 && page <= lastPage)
      .map((page) => (
        <li key={`pagination_li_${page}`} className={page === pageNumber ? "uk-active" : ""}>
          {page === pageNumber ? (
            <span>{page}</span>
          ) : (
              <button onClick={() => { changePage(page) }} className="uk-button uk-button-text" title={`Page ${page}`}>
                {page}
              </button>
            )}
        </li>
      )
      )}
    {pageNumber < lastPage && (
      <li>
        <button onClick={() => { changePage(pageNumber + 1) }} className="uk-button uk-button-text" title={`Next Page`}>
          <span uk-pagination-next="true" />
        </button>
      </li>
    )}
  </ul>
);

//lazy load in quill field to cut down on bundle size
const LazyQuillField = lazy(() => import('./core/object/fields/QuillField'));
const QuillLoadingDiv = () => (
  <div>
    <h2>Loading...</h2>
    <p>The Quill editor is being loaded for the first time</p>
  </div>
);
export const QuillField = (props) => (
  <Suspense fallback={<QuillLoadingDiv />}>
    <LazyQuillField {...props} />
  </Suspense>
);