import { makeAutoObservable } from 'mobx';
import { version } from '../../package.json';
import { firebaseConfig } from '../firebase';

const storeConfig = {
    store: false,
};

class SystemStore {
    darkTheme = false;
    version = version;
    store = null;

    constructor(store) {
        makeAutoObservable(this, storeConfig);
        this.store = store;
    }

    toggleDarkTheme = () => {
        this.darkTheme = !this.darkTheme;
    };

    get env() {
        const projectId = firebaseConfig.projectId.toLowerCase();
        if (projectId.includes('dev')) {
            return 'DEV';
        } else if (projectId.includes('qa')) {
            return 'QA';
        } else if (projectId.includes('uat')) {
            return 'UAT';
        } else {
            return 'PROD';
        }
    }

    get site() {
        return `https://${firebaseConfig.authDomain}`;
    }

    get isLoaded() {
        return this.store?.auth?.isLoaded;
    }
}

export default SystemStore;