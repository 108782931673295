import { makeObservable } from 'mobx';
import { firestoreDb } from '../firebase';
import FirestoreCollectionService from '../services/FirestoreCollectionService';

const serviceConfig = {
    db: firestoreDb,
    collectionName: 'campaign',
    objectSortColumn: 'createdOn',
    objectSortDirection: 'desc',
};
const storeConfig = {
    store: false,
    objectCollection: false,
};

class CampaignStore extends FirestoreCollectionService {
    store = null;

    constructor(store) {
        super(serviceConfig);
        makeObservable(this, storeConfig);
        this.store = store;
    }

    objectCollection = (view='default') => {
        let collectionRef = this.db.collection(this.collectionName);
        if (view==='user' && this.store.auth.user!==null) {
            collectionRef = collectionRef.where('createdByUser','==',this.store.auth.user.uid);
        }
        return collectionRef;
    };
}

export default CampaignStore;