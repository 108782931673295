import { makeAutoObservable } from 'mobx';
import { firebaseApp, firebaseConfig, useGoogleAuthProvider } from '../firebase';

const storeConfig = {
    store: false,
    handleAuthResponse: false,
    signInWithGoogle: false,
    logoutUser: false,
};

class AuthenticationStore {
    user = null;
    userClaims = null;
    idToken = null;
    userRole = null;
    isLoaded = false;
    recaptchaSiteKey = firebaseConfig.recaptchaSiteKey;
    store = null;

    constructor(store) {
        makeAutoObservable(this, storeConfig);
        this.store = store;
        firebaseApp.auth().onAuthStateChanged(this.handleAuthResponse);
    }

    get isLoggedIn() {
        return !!this.user;
    }

    get isAdmin() {
        return !!this.userClaims?.admin;
    }

    setLoaded = (isLoaded = true) => {
        this.isLoaded = isLoaded;
    }

    setUser = (user = null, userClaims = null, token = null) => {
        this.user = user;
        this.userClaims = userClaims;
        this.idToken = token;
    };

    handleAuthResponse = async (user) => {
        if (user) {
            const tokenResult = await user.getIdTokenResult();
            this.setUser(user, tokenResult.claims, tokenResult.token);
        } else if (this.isLoggedIn) {
            this.setUser();
        }
        this.setLoaded();
    };

    signInWithGoogle = async (redirect = true) => {
        try {
            const googleProvider = useGoogleAuthProvider();
            await firebaseApp.auth().signInWithPopup(googleProvider);
            if (redirect) this.store.changeRoute('/');
        } catch (err) {
            if (err.code !== 'auth/popup-closed-by-user') {
                console.warn(err);
            }
        }
    };

    logoutUser = async () => {
        try {
            this.store.changeRoute('/login');
            await firebaseApp.auth().signOut();
        } catch (err) {
            console.warn(err);
        }
    };
}

export default AuthenticationStore;