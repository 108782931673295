import React from 'react';
import { Link } from 'react-router-dom';

// import { global_labels } from '../resources/labels';

import giftBoxSvg from '../resources/assets/landing/undraw_Gift_box_re_vau4.svg';
import businessShopSvg from '../resources/assets/landing/undraw_business_shop_qw5t.svg';
import toTheMoonSvg from '../resources/assets/landing/undraw_to_the_moon_v1mv.svg';
import twoFactorSvg from '../resources/assets/landing/undraw_two_factor_authentication_namy.svg';
import { observer } from 'mobx-react-lite';


const LandingSection = ({
    flip = false, mediaBreakpoint = 's',
    sectionType, renderFn, title, subtitle, imgSrc, imgAlt
}) => {
    const textAlignClass = renderFn ? '' : (flip ? `uk-text-right@${mediaBreakpoint}` : `uk-text-left@${mediaBreakpoint}`);
    const flipClass = flip ? `uk-flex-first@${mediaBreakpoint}` : '';
    return (
        <div className={`uk-section  ${sectionType && `uk-section-${sectionType}`}`} uk-scrollspy='cls:uk-animation-fade; repeat: true;'>
            <div className='uk-container uk-container-small'>
                <div className='uk-grid-medium uk-flex-center uk-flex-middle' uk-grid='true' style={{ minHeight: '300px' }}>
                    <div className={`uk-text-center uk-width-expand@${mediaBreakpoint} ${textAlignClass}`}>
                        <h3 className={`uk-heading-small`}>{title}</h3>
                        {renderFn ? renderFn() : (
                            <p className={`uk-text-lead`}>{subtitle}</p>
                        )}
                    </div>
                    <img src={imgSrc} className={`uk-width-1-3@${mediaBreakpoint} ${flipClass}`} style={{ maxWidth: '50vw' }} alt={imgAlt} />
                </div>
            </div>
        </div>
    );
};

const LandingPage = observer(({ context }) => {
    const { darkTheme } = context.system;
    const { isLoggedIn } = context.auth;
    const defaultSection = darkTheme ? 'secondary' : 'default';
    const mutedSection = darkTheme ? false : 'muted';
    const primarySection = 'primary';
    return (
        <>
            <LandingSection title='Ready, Set, Go!' subtitle='High-quality goods, made in America.'
                imgSrc={toTheMoonSvg} imgAlt='Spaceship next to astronaut' sectionType={primarySection} flip={true}
                renderFn={() => {
                    if (isLoggedIn) {
                        return (
                            <div className='uk-flex uk-flex-center'>
                                <Link to='/user' className='uk-button uk-button-primary uk-link-button'>View User</Link>
                            </div>
                        );
                    } else {
                        return (
                            <div className='uk-flex uk-flex-center'>
                                <Link to='/signUp' className='uk-button uk-button-primary uk-link-button uk-margin-right'>Sign Up</Link>
                                <Link to='/login' className='uk-button uk-button-default uk-link-button'>Login</Link>
                            </div>
                        );
                    }
                }} />
            <LandingSection title='Purchase With Pride' subtitle='High-quality goods, made in America.'
                imgSrc={giftBoxSvg} imgAlt='Woman opening present' sectionType={defaultSection} />
            <LandingSection title='Your Digital Storefront' subtitle='Focus on producing unique products. Let us handle infrastructure and logistics.'
                imgSrc={businessShopSvg} imgAlt='Digital store front managed by you' sectionType={mutedSection} flip={true} />
            <LandingSection title='Bank Vault Security' subtitle='Rest at ease knowing your data is handled with the utmost care.'
                imgSrc={twoFactorSvg} imgAlt='High security' sectionType={defaultSection}/>
        </>

    );
});

export default LandingPage;