import React from 'react';

import { QuillField } from '../../components/Util';
import { firebaseStorage } from '../../firebase';

class CloudGallery extends React.PureComponent {
  state = {
    images: null
  };
  async componentDidMount() {
    const { imageData } = this.props;
    const imageRefs = imageData.map(({ caption, file }) => ({
      caption: caption,
      ref: firebaseStorage.ref(file.cloudPath)
    }));
    const imageDownloadUrls = await Promise.all(imageRefs.map(r => r.ref.getDownloadURL()));
    for (let i = 0; i < imageRefs.length; i++) {
      imageRefs[i].url = imageDownloadUrls[i];
    };
    this.setState(() => ({ images: imageRefs }));
  }
  render() {
    return (
      <div className='uk-position-relative uk-visible-toggle uk-margin'
        tabIndex='-1' uk-slideshow='autoplay: true; index: 0;'>
        <ul className='uk-slideshow-items uk-light'>
          {this.state.images && this.state.images.map(({ caption, url }) => (
            <li key={`slide_${caption}`}>
              <img src={url} alt={caption} uk-cover='true' />
              <div className='uk-position-bottom-center uk-position-small'>
                {caption}
              </div>
            </li>
          ))}
        </ul>
        <ul className='uk-slideshow-nav uk-dotnav uk-flex-center uk-margin' />
      </div>
    )
  }
}

export const campaignCategoryList = [
  { label: '', value: '' },
  { label: 'Test Category', value: 'testCategory' }
];

export const campaignDefinition = {
  collectionName: 'campaign',
  objectName: 'Campaign',
  allowed: ({ view, formMode, data, user }) => {
    const allow = {
      read: true
    };
    if (user) {
      allow.create = true;
      if (formMode==='create') {
        allow.update = true;
      }
    }
    if (data?.createdByUser === user?.uid) {
      allow.update = true;
      allow.delete = true;
    }
    return allow;
  },
  views: ({ view, data, user }) => {
    const allowedViews = [
      // { key: 'default', label: 'All' },
    ];
    if (user) {
      allowedViews.push({
        key: 'user', label: 'My Campaigns',
      });
    }
    return allowedViews;
  },
  objectFields: {
    id: { label: 'Campaign Id', type: 'text' },
    name: { label: 'Name', type: 'text' },
    category: {
      label: 'Category', type: 'dropdown', listEntries: campaignCategoryList, rowCalculationFn: ({ category }) => category || ''
    },
    subtitle: { label: 'Subtitle', type: 'text' },
    description: { label: 'Description', type: 'quill', expandByDefault: true },
    whoWeAre: { label: 'Who We Are', type: 'quill', expandByDefault: true },
    risks: { label: 'Risks', type: 'quill', expandByDefault: false },
    faq: {
      label: 'Frequently Asked Questions', type: 'array', configurable: true, fields: [
        { key: 'question', label: 'Question', type: 'text', required: true },
        { key: 'answer', label: 'Answer', type: 'quill', required: true, expandByDefault: true, configOnly: true },
        { type: 'config', label: 'Edit' },
        { type: 'delete', label: 'Delete' }
      ]
    },
    gallery: {
      label: 'Gallery', type: 'array', expandByDefault: true, configurable: true, fields: [
        { key: 'caption', label: 'Caption', type: 'text', required: true },
        { key: 'file', label: 'File', type: 'file', configOnly: true, required: true },
        { type: 'config', label: 'Edit' },
        { type: 'delete', label: 'Delete' }
      ]
    },
    createdOn: { label: 'Created On', type: 'timestamp', readOnly: true },
    createdBy: { label: 'Created By', type: 'text', readOnly: true },
    updatedOn: { label: 'Updated On', type: 'timestamp', readOnly: true },
    updatedBy: { label: 'Updated By', type: 'text', readOnly: true },
  },
  tableDataMapping: [
    { key: 'id', viewLink: true },
    { key: 'name' },
    { key: 'category' },
    { key: 'createdOn' }
  ],
  editDataMapping: [
    { key: 'name', required: true },
    { key: 'subtitle' },
    { key: 'category', required: true },
    { key: 'whoWeAre' },
    { key: 'description', required: true },
    { key: 'risks' },
    { key: 'faq' },
    { key: 'gallery' }
  ],
  createDataMapping: [
    { key: 'name', required: true },
    { key: 'subtitle' },
    { key: 'category', required: true },
    { key: 'whoWeAre', required: true },
    { key: 'description', required: true },
    { key: 'risks', required: true },
    { key: 'faq' },
    { key: 'gallery' }
  ],
  // viewDataTransform: (data) => {
  //   return <>
  //     <h1>{data.name}</h1>
  //     {data.subtitle && <em>{data.subtitle}</em>}
  //     {data.gallery && data.gallery.length > 0 &&
  //       <CloudGallery imageData={data.gallery} />}
  //     {data.whoWeAre && <>
  //       <h2>Who We Are</h2>
  //       <QuillField id={`page_content_whoWeAre_${data.id}`} readOnly={true} content={JSON.parse(data.whoWeAre)} />
  //     </>}
  //     {data.description && <>
  //       <h2>Description</h2>
  //       <QuillField id={`page_content_description_${data.id}`} readOnly={true} content={JSON.parse(data.description)} />
  //     </>}
  //     {data.risks && <>
  //       <h2>Risks</h2>
  //       <QuillField id={`page_content_risks_${data.id}`} readOnly={true} content={JSON.parse(data.risks)} />
  //     </>}
  //     {data.faq && data.faq.length > 0 && <>
  //       <h2>FAQ</h2>
  //       {data.faq.map(({ question, answer }) => <React.Fragment key={`faq_${question}`}>
  //         <h3>{question}</h3>
  //         <QuillField id={`faq_${question}_quill`} readOnly={true} content={JSON.parse(answer)} />
  //       </React.Fragment>)}
  //     </>
  //     }
  //   </>;
  // }
  viewDataTransform: (data) => {
    return <>
      <div uk-grid='true' className='uk-grid-small'>
        <div className='uk-width-2-3 '>
          <div>
            <div className='uk-background-default' style={{ position: 'sticky', top: '0', zIndex: 1000 }}>
              <h1>{data.name}</h1>
            </div>
            {data.subtitle && <em>{data.subtitle}</em>}
            {data.gallery && data.gallery.length > 0 &&
              <CloudGallery imageData={data.gallery} />}
            {data.whoWeAre && <>
              <div className='uk-background-default' style={{ position: 'sticky', top: '3.125rem', marginTop: '1em', zIndex: 1000 }}>
                <h2>Who We Are</h2>
              </div>
              <QuillField id={`page_content_whoWeAre_${data.id}`} readOnly={true} content={JSON.parse(data.whoWeAre)} />
            </>}
            {data.description && <>
              <div className='uk-background-default' style={{ position: 'sticky', top: '3.125rem', marginTop: '1em', zIndex: 1000 }}>
                <h2>Description</h2>
              </div>
              <QuillField id={`page_content_description_${data.id}`} readOnly={true} content={JSON.parse(data.description)} />
            </>}
            {data.risks && <>
              <div className='uk-background-default' style={{ position: 'sticky', top: '3.125rem', marginTop: '1em', zIndex: 1000 }}>
                <h2>Risks</h2>
              </div>
              <QuillField id={`page_content_risks_${data.id}`} readOnly={true} content={JSON.parse(data.risks)} />
            </>}
            {data.faq && data.faq.length > 0 && <>
              <div className='uk-background-default' style={{ position: 'sticky', top: '3.125rem', marginTop: '1em', zIndex: 1000 }}>
                <h2>FAQ</h2>
              </div>
              {data.faq.map(({ question, answer }) => <React.Fragment key={`faq_${question}`}>
                <h3>{question}</h3>
                <QuillField id={`faq_${question}_quill`} readOnly={true} content={JSON.parse(answer)} />
              </React.Fragment>)}
            </>
            }
          </div>
        </div>
        <div className='uk-width-1-3 uk-grid-match'>
          <div className='uk-flex uk-flex-column' style={{ position: 'sticky', top: '16px' }}>
            {[...new Array(4)].map((_,i) => (
              <div key={`example_tier_${i}`} className='uk-card uk-card-body uk-card-default' style={{ marginBottom: '16px '}}>
                <h3 className='uk-card-title'>Tier {i+1}</h3>
                <em>$cost</em>
                <p>Tier description</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>;
  }
}