import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter, useHistory } from 'react-router-dom';
import { notification } from 'uikit';
import { withContext, ApplicationContextProvider } from './appState';
import { firebaseAnalytics } from './firebase';
import { observer } from 'mobx-react-lite';

import Navbar from './components/Navbar';
import LoginPage from './components/LoginPage';
import SignUpPage from './pages/SignUpPage';
import LandingPage from './pages/LandingPage';
import CampaignPage from './pages/campaign/CampaignPage';

import { global_labels } from './resources/labels';

import './App.scss';
import { pageDefinitions } from './configuration/projectConfiguration';
import { dynamicPageRoute } from './configuration/dynamicPages';

const customPages = Object.keys(pageDefinitions);

const UserPageLazy = lazy(() => import('./pages/user/UserPage'));
const UserPage = withContext((props) => (
  <Suspense fallback={<Loading />}>
    <UserPageLazy {...props} />
  </Suspense>
));

const AdminPageLazy = lazy(() => import('./pages/admin/AdminPage'));
const AdminPage = withContext((props) => (
  <Suspense fallback={<Loading />}>
    <AdminPageLazy {...props} />
  </Suspense>
));

const SignUp = withContext(SignUpPage);

const Login = withContext(LoginPage);

const Logout = withContext(({ context }) => {
  context.auth.logoutUser().then(() => {
    notification('Logout Successful');
  });
  return <Redirect to="/" />;
});

const Landing = withContext(LandingPage);

export const Error404 = withContext(({ context }) => {
  return (
    <div className='uk-section uk-animation-slide-top-small'>
      <div className='uk-container uk-container-small uk-text-center'>
        <h2 className='uk-heading-small'>Page Not Found</h2>
        <p className='uk-text-lead'>The requested page could not be found.</p>
      </div>
    </div>
  );
});

export const Loading = withContext(({ context }) => {
  return (
    <div className='uk-section uk-animation-slide-top-small'>
      <div className='uk-container uk-container-small uk-text-center'>
        <h2 className='uk-heading-small'>Loading...</h2>
        <p className='uk-text-lead'>The requested page is being loaded.</p>
      </div>
    </div>
  );
});

function App({ location, history, context }) {
  useEffect(() => {
    context.history = history;
  }, [context, history]);
  const { darkTheme, isLoaded, env, version } = context.system;

  const ukBackground = darkTheme ? "uk-background-secondary" : "uk-background-muted";
  const ukTheme = darkTheme ? "uk-light" : "uk-dark";
  const CatchAllComponent = !isLoaded ? Loading : Error404;
  const appOpacity = isLoaded ? 1 : 0;
  return (
    <div className={`AppContainer ${ukBackground} ${ukTheme}`}>
      <Route path="/**" component={Navbar} />
      <main className="App" style={{ opacity: appOpacity }}>
        {isLoaded ? (
          <Suspense fallback={<Loading />}>
            <Switch location={location}>
              <Route exact path="/signUp" component={SignUp} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/" component={Landing} />
              <Route path="/user" component={UserPage} />
              <Route path="/admin" component={AdminPage} />
              <Route path="/campaign" component={CampaignPage} />
              {customPages.map((page) => {
                const PageComponent = dynamicPageRoute(page);
                return <Route path={page} component={PageComponent} key={`dyn_route_${page.code}`} />;
              })}
              <Route path="/**" component={CatchAllComponent} />
            </Switch>
          </Suspense>
        ) : (
            <Loading />
          )}
      </main>
      <footer className='uk-margin-large-top'>
        <div className='uk-text-center uk-margin-top uk-margin-large-bottom'>
          <p className='uk-text-small'>&copy; 2020 {global_labels.title}</p>
        </div>
        <div className='AppFooter'>
          <div className="uk-label uk-label-default">
            {/* {this.rebuildAlgoliaIndexesOCL && this.state.isAdmin &&
              <button className={`uk-margin-small-right uk-button uk-button-link`}
                uk-tooltip={'Rebuild Search Index'} uk-icon='icon: database;'
                onClick={this.rebuildAlgoliaIndexesOCL} />} */}
            {/* <button className={`uk-button uk-button-link uk-color-prop-fix`} uk-tooltip='Toggle Dark Theme'
                    uk-icon='icon: paint-bucket;' onClick={context.system.toggleDarkTheme} /> */}
            <span className='uk-text-uppercase'>{env} - {version}</span>
          </div>
        </div>

      </footer>
    </div>
  );
}

const AppWithRouter = withRouter(withContext(observer(App)));

//listen to history changes and log them in Firebase Analytics
const logCurrentPage = () => {
  firebaseAnalytics.setCurrentScreen(window.location.pathname);
  firebaseAnalytics.logEvent('screen_view');
};
const AnalyticsListener = () => {
  const history = useHistory();
  useEffect(() => {
    logCurrentPage();
    history.listen(logCurrentPage);
  }, [history]);
  return <></>;
};

const ScrollToTopComponent = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);
  return null;
}
const ScrollToTop = withRouter(ScrollToTopComponent);

const RoutedApp = () => (
  <BrowserRouter>
    <ApplicationContextProvider>
      <AppWithRouter />
    </ApplicationContextProvider>
    <ScrollToTop />
    <AnalyticsListener />
  </BrowserRouter>
);

export default RoutedApp;
