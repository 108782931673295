import React from 'react';
import { Formik } from 'formik';

export default function FormikWorkflowState({
    children, code,
    nextState = null, stateChangeHandler = () => nextState,
    validate = () => ({}), initialValues = {},
    stateMachine,
}) {
    const { changeState, updateObject, latestObject } = stateMachine;
    return <Formik
        initialValues={{ ...initialValues, ...latestObject }}
        validate={validate}
        onSubmit={async (fields) => {
            await updateObject(fields);
            changeState(stateChangeHandler(fields, code))();
        }}
    >
        {formik => children(formik, stateMachine)}
    </Formik>;
}